import { Route, RouteProps } from "react-router-dom"

import RouteRedirect from "../RouteRedirect"
import useAuthRedirect from "./useAuthRedirect"

export default function AuthRoute({ ...rest }: RouteProps) {
  const { isAuth, authRoute } = useAuthRedirect()
  return (
    <RouteRedirect passCondition={isAuth} to={authRoute}>
      <Route {...rest} />
    </RouteRedirect>
  )
}
