import s from "./IconHeader.module.scss"

type IconHeaderProps = {
  icon: React.ComponentType<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >
  title: string
}

function IconHeader({ icon: Icon, title }: IconHeaderProps) {
  return (
    <header className={s.header}>
      <h2>
        <Icon className={s.icon} />
        {title}
      </h2>
    </header>
  )
}

export default IconHeader
