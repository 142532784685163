type Props = {
  names: Array<string>
}

const ListNames = ({ names }: Props) => {
  if (names.length === 0) {
    return null
  }
  if (names.length === 1) {
    return (
      <span>
        <strong>{names[0]}</strong>
      </span>
    )
  }
  if (names.length === 2) {
    return (
      <span>
        <strong>{names[0]}</strong>
        <span> and </span>
        <strong>{names[1]}</strong>
      </span>
    )
  }
  const ls = new Array<JSX.Element>()
  for (let i = 0; i < names.length - 1; i += 1) {
    const name = names[i]
    ls.push(<strong key={`${name}_n`}>{name}</strong>)
    ls.push(<span key={`${name}_s`}>{i < names.length - 2 ? ", " : " "}</span>)
  }
  ls.push(<span key={`${names[names.length - 1]}_s`}>and </span>)
  ls.push(
    <strong key={`${names[names.length - 1]}_n`}>{names[names.length - 1]}</strong>
  )
  return <span>{ls}</span>
}

export default ListNames
