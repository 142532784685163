import React from "react"
import { Redirect, RedirectProps } from "react-router-dom"

interface Props extends RedirectProps {
  passCondition: boolean
  children: React.ReactNode
}

const RouteRedirect = ({ children, to, passCondition }: Props) =>
  passCondition ? <>{children}</> : <Redirect to={to} />

export default RouteRedirect
