import splitString, { EllipsisAt } from "../../lib/splitString"
import s from "./OneLineSummary.module.scss"

type Props = {
  /** If passed as a number then this is used as the index of where to split
   * the string. If passed as a function then this is run with `children` as
   * its first parameter and should return the index to split the string at. If
   * `-1` is given (the default) or returned from the function then `children`
   * is not split and the result is an ellipsis at the end of the line */
  ellipsisAt?: number | ((s: string) => number)
  /** the text that will be summarized */
  children: string
}

/** Provides a summary up to the width of the parent container followed by an
 * ellipsis (…). If `ellipsisAt` is provided then split the string at some
 * point in the middle of `children`. */
function OneLineSummary({ children, ellipsisAt = -1 }: Props) {
  const [rest, alwaysShown] = splitString(children, ellipsisAt as EllipsisAt)
  return (
    <span className={s.container}>
      <span className={s.rest}>{rest}</span>
      {alwaysShown && <span className={s.alwaysShown}>{alwaysShown}</span>}
    </span>
  )
}

export default OneLineSummary
