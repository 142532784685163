import { Form, Formik } from "formik"
import React from "react"

import { errorMessages } from "../../../constants/retain"
import AccessibleFormikFormField from "../AccessibleFormikFormField"
import Button, { ButtonFill } from "../Button"
import ErrorMessage from "../ErrorMessage"
import PopIn from "../PopIn"

interface Props {
  shown: boolean
  hide: () => void
  confirmation?: string
  error: boolean
  loading: boolean
  onDelete: () => void
  children: React.ReactNode
}

const DeletePopIn = ({
  shown,
  hide,
  confirmation = "DELETE",
  error,
  loading,
  onDelete,
  children,
}: Props) => (
  <PopIn hide={hide} shown={shown}>
    <div className="action-box">
      {children}
      <Formik
        initialValues={{
          inputDelete: "",
        }}
        onSubmit={(): void => {
          onDelete()
        }}
      >
        {({ isSubmitting, values, errors, touched, submitForm }): JSX.Element => {
          const disabled =
            isSubmitting || values.inputDelete !== confirmation || loading
          return (
            <Form>
              <label htmlFor="inputDelete">
                Type <strong>{confirmation}</strong> to confirm
              </label>
              <AccessibleFormikFormField
                errors={errors}
                touched={touched}
                slug="inputDelete"
                errorSlug="inputDeleteError"
              />
              {error && (
                <ErrorMessage id="inputDeleteError" msg={errorMessages.REQUEST} />
              )}
              <Button onClick={submitForm} fill={ButtonFill.DANGER} disabled={disabled}>
                Delete
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  </PopIn>
)

export default DeletePopIn
