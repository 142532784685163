import type { ContextData } from "@floating-ui/react-dom-interactions"
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from "@floating-ui/react-dom-interactions"
import cx from "classnames"
import {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react"

import { ReactComponent as Chevron } from "../../../images/Chevron.svg"
import s from "./Select.module.scss"

interface SelectContextValue {
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  activeIndex: number | null
  setActiveIndex: (index: number | null) => void
  listRef: React.MutableRefObject<Array<HTMLLIElement | null>>
  setOpen: (open: boolean) => void
  onChange: (value: string) => void
  // eslint-disable-next-line
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => any // type from floating-ui
  dataRef: ContextData
}

const SelectContext = createContext({} as SelectContextValue)
type Props = {
  value: string
  index?: number
  key?: string
  children: React.ReactNode
  className?: string
  overrideOnClick?: () => unknown
}

export const Option = ({
  children,
  index = 0,
  value,
  className,
  overrideOnClick,
}: Props) => {
  const {
    selectedIndex,
    setSelectedIndex,
    listRef,
    setOpen,
    onChange,
    activeIndex,
    setActiveIndex,
    getItemProps,
    dataRef,
  } = useContext(SelectContext)

  function handleSelect() {
    setSelectedIndex(index)
    if (overrideOnClick) {
      overrideOnClick()
    } else {
      onChange(value)
    }
    setOpen(false)
    setActiveIndex(null)
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Enter" || (event.key === " " && !dataRef.current.typing)) {
      event.preventDefault()
      handleSelect()
    }
  }

  return (
    <li
      className={cx(s.SelectOption, className, s.MainText)}
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      tabIndex={activeIndex === index ? 0 : 1}
      // activeIndex === index prevents VoiceOver stuttering.
      aria-selected={activeIndex === index && selectedIndex === index}
      data-selected={selectedIndex === index}
      {...getItemProps({
        onClick: handleSelect,
        onKeyDown: handleKeyDown,
      })}
    >
      {children}
    </li>
  )
}

type SelectProps = {
  name?: string
  onChange?: (value: string) => void
  renderLabel?: (selectedIndex: number) => React.ReactNode
  value: string
  children: React.ReactNode
  labelledBy?: string
  describedBy?: string
  disabled?: boolean
  classNames?: {
    selectDropdown?: string
  }
}

function Select({
  name,
  children,
  value,
  renderLabel,
  onChange = () => {},
  disabled = false,
  labelledBy,
  describedBy,
  classNames,
}: SelectProps) {
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([])
  const listContentRef = useRef([
    ...(Children.map(children, (child) => isValidElement(child) && child.props.value) ??
      []),
  ])

  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(
    Math.max(0, listContentRef.current.indexOf(value))
  )
  const [controlledScrolling, setControlledScrolling] = useState(false)

  const ref = useRef<number | null>()

  useLayoutEffect(() => {
    ref.current = activeIndex
  }, [activeIndex])

  const prevActiveIndex = ref.current

  const { x, y, reference, floating, strategy, context, refs } = useFloating({
    open,
    onOpenChange: (value) => !disabled && setOpen(value),
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 8 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          })
        },
        padding: 8,
      }),
    ],
  })

  const floatingRef = refs.floating

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: "listbox" }),
    useDismiss(context, { outsidePressEvent: "mousedown" }),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      selectedIndex,
      onNavigate: setActiveIndex,
    }),
    useTypeahead(context, {
      listRef: listContentRef,
      onMatch: open ? setActiveIndex : setSelectedIndex,
      activeIndex,
      selectedIndex,
    }),
  ])

  // Scroll the active or selected item into view when in `controlledScrolling`
  // mode (i.e. arrow key nav).
  useLayoutEffect(() => {
    const floating = floatingRef.current

    if (open && controlledScrolling && floating) {
      const item =
        activeIndex != null
          ? listItemsRef.current[activeIndex]
          : selectedIndex != null
          ? listItemsRef.current[selectedIndex]
          : null

      if (item && prevActiveIndex != null) {
        const itemHeight = listItemsRef.current[prevActiveIndex]?.offsetHeight ?? 0

        const floatingHeight = floating.offsetHeight
        const top = item.offsetTop
        const bottom = top + itemHeight

        if (top < floating.scrollTop) {
          floating.scrollTop -= floating.scrollTop - top + 5
        } else if (bottom > floatingHeight + floating.scrollTop) {
          floating.scrollTop += bottom - floatingHeight - floating.scrollTop + 5
        }
      }
    }
  }, [
    open,
    controlledScrolling,
    prevActiveIndex,
    activeIndex,
    floatingRef,
    selectedIndex,
  ])

  // Sync the height and the scrollTop values
  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      const floating = refs.floating.current
      if (open && floating && floating.clientHeight < floating.scrollHeight) {
        const item = listItemsRef.current[selectedIndex]
        if (item) {
          floating.scrollTop =
            item.offsetTop - floating.offsetHeight / 2 + item.offsetHeight / 2
        }
      }
    })
  }, [open, selectedIndex, refs])

  let optionIndex = 0
  const options = [
    Children.map(
      children,
      (child) =>
        isValidElement(child) &&
        cloneElement(child, {
          key: child.props.value,
          tabIndex: -1,
          // eslint-disable-next-line no-plusplus
          index: 1 + optionIndex++,
        })
    ),
  ]

  return (
    <SelectContext.Provider
      value={{
        selectedIndex,
        setSelectedIndex,
        activeIndex,
        setActiveIndex,
        listRef: listItemsRef,
        setOpen,
        onChange,
        getItemProps,
        dataRef: context.dataRef,
      }}
    >
      <button
        type="button"
        aria-describedby={describedBy}
        aria-labelledby={labelledBy}
        {...getReferenceProps({
          ref: reference,
          className: cx(s.SelectButton, { [s.disabled]: disabled }, s.MainText),
        })}
      >
        {!!renderLabel && renderLabel(selectedIndex - 1)}
        <Chevron className={cx({ [s.chevronOpen]: open })} />
      </button>
      <input type="hidden" name={name} value={value} />
      {open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: 9002,
            }}
          >
            <FloatingFocusManager context={context}>
              <div
                {...getFloatingProps({
                  ref: floating,
                  className: cx(s.SelectDropdown, classNames?.selectDropdown),
                  style: {
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    overflow: "auto",
                  },
                  onPointerEnter() {
                    setControlledScrolling(false)
                  },
                  onPointerMove() {
                    setControlledScrolling(false)
                  },
                  onKeyDown() {
                    setControlledScrolling(true)
                  },
                })}
              >
                <ul>{options}</ul>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </SelectContext.Provider>
  )
}

export default Select
