import {
  Placement,
  offset,
  flip,
  arrow,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  FloatingPortal,
} from "@floating-ui/react-dom-interactions"
import { cloneElement, useCallback, useMemo, useRef, useState } from "react"

import getOppositeSide from "./getOppositeSide"

interface Props {
  children: JSX.Element
  label: React.ReactNode
  arrowClass?: string
  placement?: Placement
  arrowSize?: number
}

/* Code based on https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx - installing library caused tests to fail */
export function mergeRefs<T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value)
      } else if (ref !== null) {
        const mutableRef = ref as React.MutableRefObject<T | null>
        mutableRef.current = value
      }
    })
  }
}

const FloatingTooltip = ({
  children,
  label,
  arrowClass,
  placement = "bottom",
  arrowSize = 4,
}: Props) => {
  const [open, setOpen] = useState(false)

  const arrowRef = useRef(null)

  const { x, y, reference, floating, update, strategy, context, middlewareData } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(5),
        flip(),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ])

  // Preserve the consumer's ref
  const ref = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => mergeRefs([reference, children.ref]),
    [reference, children]
  )

  const arrowCallback = useCallback(
    (node) => {
      arrowRef.current = node
      update()
    },
    [update]
  )

  const { x: arrowX, y: arrowY } = middlewareData.arrow ?? {}
  const oppositeSide = getOppositeSide(context.placement)

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}

      <FloatingPortal>
        <div
          {...getFloatingProps({
            ref: floating,
            className: "Tooltip",
            style: {
              opacity: open ? 1 : 0,
              pointerEvents: open ? "auto" : "none",
              position: strategy,
              zIndex: 10000,
              left: x ?? 0,
              top: y ?? 0,
              transition: "opacity 0.3s ease-out",
              width: "auto",
            },
          })}
        >
          <div style={{ position: "relative" }}>
            <div
              {...getFloatingProps({
                ref: arrowCallback,
                className: arrowClass,
                style: {
                  position: "absolute",
                  zIndex: 10000,
                  width: arrowSize * 2,
                  height: arrowSize * 2,
                  left: arrowX && arrowX / 2,
                  top: arrowY,
                  [oppositeSide]: -arrowSize / 2,
                },
              })}
            />
            {label}
          </div>
        </div>
      </FloatingPortal>
    </>
  )
}

export default FloatingTooltip
