import dayjs from "dayjs"

import { pluralize } from "./formatters"

// Order of arguments might look peculiar but it is to keep consistent with
// date-fns differenceIn... functions
function formatInitialTerm(expiryDate: string, startDate: string) {
  if (dayjs(startDate).valueOf() - dayjs(expiryDate).valueOf() > 0) {
    throw new RangeError("startDate cannot be after expiryDate")
  }

  const duration = dayjs.duration(dayjs(expiryDate).diff(dayjs(startDate)))

  const years = duration.years()
  const months = duration.months()

  const humanizedYears = `${years} ${pluralize("year", years)}`
  const humanizedMonths = `${months} ${pluralize("month", months)}`

  if (years && !months) {
    return humanizedYears
  }
  if (months && !years) {
    return humanizedMonths
  }
  return humanizedYears.concat(", ", humanizedMonths)
}

export default formatInitialTerm
