import axios, { AxiosError, AxiosResponse } from "axios"
import cx from "classnames"
import {
  Field,
  Form,
  Formik,
  FormikHelpers as FormikActions,
  FormikErrors,
} from "formik"
import { isArray } from "lodash-es"
import { ReactElement, useState } from "react"
import { useLocation } from "react-router"
import { Link, useHistory } from "react-router-dom"

import localStorageKeys from "../../../constants/localStorageKeys"
import routes from "../../../constants/routes"
import useIdent from "../../../hooks/useIdent"
import useSetError from "../../../hooks/useSetError"
import { ReactComponent as Chevron } from "../../../images/Chevron.svg"
import { removeLocalStorageIdentificationItems } from "../../../lib/auth"
import HttpStatusCode from "../../../lib/httpStatusCodes"
import {
  removeSessionStorageItem,
  setSessionStorageItem,
} from "../../../lib/localStorageUtil"
import {
  requestPasswordConfirm,
  resetPasswordRequest,
} from "../../../redux/actions/auth"
import { getUser, updateUser } from "../../../redux/actions/user"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { FormValues } from "../../../types/retain"
import { ButtonType } from "../Button"
import { validatePassword } from "../ChangePassword/ChangePassword"
import Checkbox from "../Checkbox"
import FormError from "../FormError"
import { Button } from "../index"
import PasswordStrengthMeter from "../PasswordStrengthMeter"
import { toastNotification } from "../ToastNotification"
import s from "./ResetPassword.module.scss"

function isEmailErrorArray(data: unknown): data is { email: string[] } {
  return (
    data !== undefined &&
    typeof data === "object" &&
    data !== null &&
    "email" in data &&
    isArray((data as Record<"email", unknown>).email)
    // Casting used here to silence TS compiler, despite language server identifying the type.
    // Hopefully transient error fixed in time.
  )
}

function validateResetPassword(values: FormValues): FormValues {
  const errors: FormValues = {}
  if (!values.email) {
    errors.email = "We need your email address to reset your password."
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(values.email)) {
    errors.email =
      "This doesn't look like an email address, " +
      "we expected something like john@example.com."
  }
  return errors
}

type ResetPasswordProps = {
  create?: boolean
}

function ResetPassword({ create }: ResetPasswordProps) {
  const setError = useSetError()
  const route = useAppSelector((state) => state.route)
  const isBusinessSide = useAppSelector((state) => state.auth.businessSide)
  const resetToken = useAppSelector((state) => state.persistant.session.resetToken)

  const isConfirmingPasswordReset = useAppSelector(
    (state) =>
      state.persistant.session.identToken &&
      state.persistant.session.userId &&
      state.persistant.session.resetToken
  )

  const isCreatingPassword = useAppSelector(
    (state) => !!state.persistant.session.isCreatingPassword
  )

  const [passwordReset, setPasswordReset] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [passwordConfirmationHidden, setPasswordConfirmationHidden] = useState(true)
  const [newPassword, setNewPassword] = useState("")

  const history = useHistory()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { userId, token } = useIdent()

  function getTitle() {
    if (!create) return "Reset your password"
    if (isBusinessSide) return "Welcome to Retain"
    return "Create an account"
  }

  function handleResetResponse(
    response:
      | AxiosError<unknown>
      | AxiosResponse<{ passwordReset?: string; error?: string }>
      | undefined,
    setErrors: (errors: FormikErrors<FormValues>) => void
  ) {
    if (!response) {
      setError()
      return
    }

    if (axios.isAxiosError(response)) {
      const responseData = response.response?.data
      if (isEmailErrorArray(responseData)) {
        if (response.response?.status === HttpStatusCode.BAD_REQUEST) {
          setErrors({ email: responseData.email.join(" ") })
          return
        }
        if (response.response?.status === HttpStatusCode.CONFLICT) {
          toastNotification(responseData.email.join(" "), "error", {
            autoClose: false,
          })
          return
        }
      }

      setError(response)
      return
    }

    if (response.data?.passwordReset) {
      setPasswordReset(true)
      toastNotification("Please check your email for a reset password link", "info")
      return
    }
    // We should not end up here but if passwordReset was false
    setError()
  }

  async function resetPassword(
    values: FormValues,
    { setSubmitting, setErrors }: FormikActions<FormValues>
  ) {
    if (typeof values.email === "string") {
      setPasswordReset(false)

      const resp = await dispatch(
        resetPasswordRequest({
          email: values.email,
          userId: userId || null,
          token: token || null,
        })
      )

      handleResetResponse(resp, setErrors)

      setSubmitting(false)
    }
  }

  function validateResetPasswordConfirm(values: FormValues) {
    setNewPassword(values?.newPassword ?? "")
    return validatePassword(values)
  }

  function canSubmitPassword(values: FormValues) {
    const { newPassword = "", passwordConfirmation = "" } = values

    return newPassword === passwordConfirmation && newPassword.length > 8
  }

  function resetPasswordConfirmed(response: AxiosResponse) {
    dispatch(
      updateUser(response.data.firm, response.data.currentUser, response.data.token)
    )
    removeLocalStorageIdentificationItems()
    if (route?.redirectTo?.pathname) {
      history.push(route?.redirectTo?.pathname)
    } else {
      history.push(routes.HOME)
    }
    if (isBusinessSide) {
      dispatch(getUser())
    }
  }

  async function handleFormSubmit(
    values: FormValues,
    { setSubmitting, setErrors }: FormikActions<FormValues>
  ) {
    if (typeof values.newPassword === "string") {
      const resp = (await dispatch(
        requestPasswordConfirm({
          newPassword: values.newPassword,
          userId: userId ?? undefined,
          token: token ?? undefined,
          isCreating: isCreatingPassword,
          resetToken,
        })
      )) as unknown as AxiosResponse

      if (resp.status === 200) {
        if (!resp.data.passwordResetConfirm) {
          if (resp.data.reason === "2fa-required") {
            setSessionStorageItem(
              localStorageKeys.phoneNumberRequired,
              String(resp.data.phoneNumberRequired)
            )

            redirectToTwoFactorAuth(values.newPassword, resp.data.phoneNumberRequired)
          } else {
            setErrors(resp.data.error)
            setSubmitting(false)
          }
        } else {
          resetPasswordConfirmed(resp)
        }
      } else {
        setSubmitting(false)
      }
    }
  }

  function redirectToTwoFactorAuth(password: string, phoneNumberRequired: boolean) {
    removeSessionStorageItem(localStorageKeys.emailForTwoFA)
    setSessionStorageItem(localStorageKeys.passwordForTwoFA, password)

    history.push(routes.MULTI_FACTOR_VERIFICATION, {
      previousRoute: location.pathname,
      authFlow: "reset",
      phoneNumberRequired,
      credentials: { password },
    })
  }

  return (
    <div className="action-box-v2">
      <h2 className={s.title}>{getTitle()}</h2>
      {isConfirmingPasswordReset ? (
        <div>
          {create && (
            <div style={{ marginTop: 10 }}>
              <label
                style={{
                  fontSize: 16,
                }}
                htmlFor="email"
              >
                Please set a secure memorable password so you can enable your account.
              </label>
            </div>
          )}

          <Formik
            initialValues={{ newPassword: "", passwordConfirmation: "" }}
            validate={validateResetPasswordConfirm}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, submitForm, values, errors, touched }): ReactElement => {
              return (
                <Form data-testid="reset_password_form" className="no-gap">
                  <label htmlFor="password">New password</label>
                  <Field
                    className={cx(s.fullWidth, {
                      [s.errorInput]:
                        !!errors.passwordConfirmation && touched.passwordConfirmation,
                    })}
                    type={passwordHidden ? "password" : "text"}
                    name="newPassword"
                    aria-describedby="newPasswordError"
                    autoComplete="new-password"
                    value={values.newPassword}
                  />

                  {Boolean(values?.newPassword) && (
                    <>
                      <PasswordStrengthMeter newPassword={newPassword as string} />
                      <FormError name="newPassword" id="newPasswordError" />
                    </>
                  )}
                  <div className={s.showPasswordContainer}>
                    <Checkbox
                      id="passwordCheckbox"
                      label="Show password"
                      checked={!passwordHidden}
                      onChange={() => {
                        setPasswordHidden((isHidden) => !isHidden)
                      }}
                    />
                  </div>

                  <div className={s.marginTop}>
                    <label htmlFor="password">Confirm new password</label>
                    <Field
                      className={cx(s.fullWidth, {
                        [s.errorInput]:
                          !!errors.passwordConfirmation && touched.passwordConfirmation,
                      })}
                      type={passwordConfirmationHidden ? "password" : "text"}
                      name="passwordConfirmation"
                      aria-describedby="passwordConfirmationError"
                      autoComplete="new-password"
                    />
                    <FormError
                      name="passwordConfirmation"
                      id="passwordConfirmationError"
                    />
                    <div className={s.showPasswordContainer}>
                      <Checkbox
                        id="passwordConfirmationCheckbox"
                        label="Show password"
                        checked={!passwordConfirmationHidden}
                        onChange={() => {
                          setPasswordConfirmationHidden((isHidden) => !isHidden)
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    fullWidth
                    onClick={async (e) => {
                      e?.preventDefault()
                      await submitForm()
                    }}
                    type={ButtonType.BUTTON}
                    className="solid primary"
                    label="submit button"
                    disabled={
                      isSubmitting ||
                      !values?.newPassword ||
                      !values?.passwordConfirmation ||
                      !canSubmitPassword(values)
                    }
                    icon={
                      create ? (
                        <Chevron className="chevron-right chevron-white" />
                      ) : undefined
                    }
                  >
                    Save password
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{ email: "" }}
            validate={validateResetPassword}
            onSubmit={resetPassword}
          >
            {({ isSubmitting, values, errors, touched, submitForm }): ReactElement => {
              const disabled = isSubmitting || !values?.email
              return (
                <Form className={cx(s.inputGroup, "no-gap")}>
                  {!passwordReset && (
                    <label htmlFor="email">
                      Input your email address to receive a password reset link.
                    </label>
                  )}

                  {passwordReset && (
                    <>
                      <label htmlFor="email">
                        We've sent you an email with a reset password link. Please
                        follow the instructions in the email to reset your password.
                      </label>
                      <label htmlFor="email">
                        If you didn't receive the email you can resend one clicking the
                        button below.
                      </label>
                    </>
                  )}

                  <label htmlFor="email">Email</label>

                  <Field
                    className={cx(s.fullWidth, {
                      [s.errorInput]: !!errors.email && touched.email,
                    })}
                    placeholder="example@example.com"
                    data-testid="email_input"
                    type="email"
                    name="email"
                    aria-describedby="emailError"
                    autoComplete="email"
                  />

                  <FormError data-testid="error_message" name="email" id="emailError" />

                  <Link to="/" data-testid="login_link" className="form-link">
                    Log In
                  </Link>
                  <Button
                    label="submit button"
                    onClick={submitForm}
                    disabled={disabled}
                  >
                    {passwordReset ? "Resend email" : "Send email"}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
