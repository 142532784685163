import s from "./PasswordStrengthMeter.module.scss"

const PasswordStrengthMeter = ({ newPassword }: { newPassword: string }) => {
  // intial values
  let strength = 0
  let progress = 0
  let color = "red"

  // increases strength as user adds unique char
  const letters: Record<string, number> = {}
  for (let i = 0; i < newPassword.length; i += 1) {
    letters[newPassword[i]] = (letters[newPassword[i]] || 0) + 1
    strength += 3.75 / letters[newPassword[i]]
  }

  // checks different char types
  const variations = {
    digits: /\d/.test(newPassword),
    lower: /[a-z]/.test(newPassword),
    upper: /[A-Z]/.test(newPassword),
    nonWords: /\W/.test(newPassword),
  } as const

  // increases strength as user add more char type
  const variationCount = (
    Object.keys(variations) as Array<keyof typeof variations>
  ).reduce((a, type) => (variations[type] ? 1 : 0), 0)
  strength += variationCount * 7.5

  // color renders
  if (strength > 80) {
    // main good color
    color = "#41cd6b"
    progress = 100
  } else if (strength > 60) {
    color = "#c2d74f"
    progress = 75
  } else if (strength > 35) {
    color = "#ffc156"
    progress = 50
  } else if (strength > 10) {
    color = "#ff4c26"
    progress = 25
  } else if (strength <= 10) {
    // main bad color
    color = "#ff4c26"
    progress = 2
  }

  // renders
  return (
    <>
      <div className={s.container}>
        <div
          className={s.bar}
          style={{
            backgroundColor: color,
            width: `${progress}%`,
          }}
        />
      </div>
      <div className="passwordRequirements">
        At least 10 characters, one lowercase letter, one uppercase letter, one number,
        and one special character.
      </div>
    </>
  )
}

export default PasswordStrengthMeter
