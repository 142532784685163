import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"

import endpoints from "../../../constants/endpoints"
import reportAxiosError from "../../../lib/reportAxiosError"
import { logOut } from "../../../redux/actions/auth"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { toastNotification } from "../ToastNotification"

const AUTH_EXPIRY_NOTIFICATION_TIME = 180000

function useAuthExpiry() {
  const dispatch = useAppDispatch()

  const [showAuthExpiryPopover, setShowAuthExpiryPopover] = useState(false)
  const authExpiryTimeoutId = useRef<number | undefined>(undefined)
  const notificationTimeoutId = useRef<number | undefined>(undefined)

  /* We need to use useState and useRef for authExpiry and isAuthRef values because they are referenced inside a callback function
   * passed to `axios.interceptors.response.use` and we need to ensure they are using the latest values.
   * We don't want to keep resetting the callback function on each update by passing authExpiry and isAuth to the deps array in the useEffect. */
  const [authExpiry, setAuthExpiry] = useState<string | undefined>(undefined)
  const authExpiryRef = useRef<string | undefined>(authExpiry)

  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const isAuthRef = useRef(isAuth)

  useEffect(() => {
    if (isAuthRef.current && !isAuth) {
      clearAuthTimers()
    }
    isAuthRef.current = isAuth
  }, [isAuth])

  useEffect(() => {
    const interceptorResponse = axios.interceptors.response.use((response) => {
      const expiry = response.headers["x-auth-expiry"]
      if (expiry) {
        setAuthExpiry(expiry)
        const authExpiryHasChanged = authExpiryRef.current !== expiry
        if (isAuthRef.current && authExpiryHasChanged) {
          authExpiryRef.current = expiry
          setAuthTimers(expiry)
        }
      }
      return response
    })

    return () => {
      axios.interceptors.response.eject(interceptorResponse)
    }
  })

  function refreshAuthExpiry() {
    axios.get(endpoints.user).catch((e: unknown) => {
      reportAxiosError(e)
    })
  }

  function setAuthTimers(authExpiry: string) {
    const timeoutToLogout = dayjs(authExpiry, "YYYY-MM-DD hh:mm:ss.SSS000Z").diff(
      new Date(),
      "millisecond"
    )
    const timeoutToNotification = timeoutToLogout - AUTH_EXPIRY_NOTIFICATION_TIME
    clearAuthTimers()
    notificationTimeoutId.current = window.setTimeout(() => {
      setShowAuthExpiryPopover(true)
    }, timeoutToNotification)

    authExpiryTimeoutId.current = window.setTimeout(() => {
      setShowAuthExpiryPopover(false)
      dispatch(logOut())
      toastNotification("We logged you out due to inactivity.", "info")
    }, timeoutToLogout)
  }

  function clearAuthTimers() {
    clearTimeout(notificationTimeoutId.current)
    clearTimeout(authExpiryTimeoutId.current)
  }

  function dismissAuthNotification() {
    refreshAuthExpiry()
    setShowAuthExpiryPopover(false)
  }

  return {
    authExpiry,
    shown: showAuthExpiryPopover,
    dismiss: dismissAuthNotification,
  }
}

export default useAuthExpiry
