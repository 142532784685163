import runtimeEnv from "@mars/heroku-js-runtime-env"

const env = runtimeEnv()

function checkIsDevLikeEnvironment() {
  return (
    env.REACT_APP_ENVIRONMENT === "development" ||
    env.REACT_APP_ENVIRONMENT === "staging" ||
    env.REACT_APP_ENVIRONMENT === "review"
  )
}

export default checkIsDevLikeEnvironment
