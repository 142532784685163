import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router"

import routes from "../../../constants/routes"
import { updateRedirect } from "../../../redux/actions/route"
import { useAppSelector } from "../../../redux/store"

export default function useAuthRedirect() {
  const location = useLocation()
  const dispatch = useDispatch()
  const redirectTo = useAppSelector((state) => state.route?.redirectTo)
  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const isKba = useAppSelector((state) => state.auth.firm?.isKba)
  const authRoute = isKba ? routes.CONSUMER.PORTAL.LOGIN : routes.HOME

  useEffect(() => {
    if (!isAuth && !redirectTo) {
      dispatch(updateRedirect(location))
    }
    /* TODO: ENG-5731 Remove eslint-disable for dependency arrays in hooks */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return { isAuth, authRoute }
}
