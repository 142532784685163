import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionInfoPageBlocks = {
  lender: {
    selection: {
      productDetails: {
        reversionRateContent: {
          type: "text",
          description: "Reversion rate content",
          default: "Reversion rate",
        },
        followOnPaymentContent: {
          type: "text",
          description: "Follow on payment content",
          default: "Follow-on payment",
        },
        tempDescriptionMdx: {
          type: "mdx",
          description: "Aldermore Temp description content",
          default: ` <p>
    The initial rate for this product is {props.preferentialRate} (variable follow-on rate less
    {props.discountRate}).
  </p>
  <p>
    This product has a 10% annual overpayment allowance and is subject to ERCs
    during the initial term.
  </p>
  <p>
    This variable product allows you to switch to a new fixed rate from our
    product range, during the 2 year discount period without incurring ERCs.
  </p>`,
        },
        label: {
          collapsedContent: {
            type: "text",
            description: "Collapsed label content",
            default: "See details",
          },
          expandedContent: {
            type: "text",
            description: "Expanded label content",
            default: "Hide details",
          },
        },
      },
    },
  },
} as const

export type ProductSelectionInfoLabelPaths = LabelPaths<
  typeof productSelectionInfoPageBlocks
>
