import localStorageKeys, { functional } from "../constants/localStorageKeys"
import {
  getLocalStorageItem,
  removeLocalStorageItems,
  setLocalStorageItem,
} from "./localStorageUtil"
import logSentryBreadcrumb from "./logSentryBreadcrumb"

export type Consent = { functional: boolean }

export default function cookieConsent(functionalValue?: boolean): Consent | null {
  let current: Consent | null = null

  const currentStorageItem = getLocalStorageItem(localStorageKeys.cookieConsentKey)

  try {
    const parseCurrentItem = currentStorageItem && JSON.parse(currentStorageItem)
    if (parseCurrentItem) {
      current = parseCurrentItem
    }
  } catch (e) {
    logSentryBreadcrumb({
      message: `Json parse error in cookieConsent. Error: ${e}`,
      level: "error",
    })
  }

  if (functionalValue === undefined) {
    return current
  }

  // if current is null then set functional to 1
  if (current === null) {
    current = { functional: true }
  }

  // if there is functionalValue to update and it is not the same as current value then update
  if (current.functional !== functionalValue) {
    current.functional = functionalValue
  }

  if (!current.functional) {
    removeLocalStorageItems(Object.values(functional))
  }

  try {
    setLocalStorageItem(localStorageKeys.cookieConsentKey, JSON.stringify(current))
  } catch (error) {
    logSentryBreadcrumb({
      message: `An error occurred while stringify 'current': ${error}`,
      level: "error",
    })
  }
  return current
}
