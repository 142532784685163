import runtimeEnv from "@mars/heroku-js-runtime-env"
import * as Sentry from "@sentry/react"

const devEnv = runtimeEnv().REACT_APP_ENVIRONMENT === "development"

export default function logSentryBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb)
  if (devEnv) {
    if (breadcrumb.type !== "error") {
      console.log(breadcrumb.message, breadcrumb)
    } else {
      console.error(breadcrumb.message, breadcrumb)
    }
  }
}
