import { ReactComponent as Alert } from "../../../images/Alert.svg"
import { ReactComponent as TickNoCircle } from "../../../images/TickNoCircle.svg"
import s from "./Icons.module.scss"

const Tick = () => (
  <div className={`${s.toastIcon} ${s.toastIconSuccess}`}>
    <TickNoCircle />
  </div>
)

const Bell = () => (
  <div className={`${s.toastIcon} ${s.toastIconError}`}>
    <Alert />
  </div>
)

export { Tick, Bell }
