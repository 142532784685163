import cx from "classnames"
import React, { forwardRef, LegacyRef } from "react"

import { ButtonTrackingId } from "../../../constants/buttonTrackingIds"
import useTrackClick from "../../../hooks/trackClick"
import { handleEnterPressed } from "../../../lib/keyboardEvents"
import s from "./ButtonLink.module.scss"

interface Props {
  center?: boolean
  className?: string
  label?: string
  onClick: () => void
  onEnter?: () => void
  trackingId?: ButtonTrackingId
  children: React.ReactNode
}

const ButtonLink = forwardRef(
  (
    { center, className, label, onClick, onEnter, children, trackingId }: Props,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const { trackClick } = useTrackClick()
    return (
      <div
        ref={ref}
        className={cx(s.button, { [s.center]: center, [className || ""]: className })}
        role="button"
        aria-label={label}
        tabIndex={0}
        onClick={(): void => {
          if (trackingId) trackClick(trackingId)
          onClick()
        }}
        onKeyDown={handleEnterPressed(onEnter || onClick)}
      >
        {children}
      </div>
    )
  }
)

ButtonLink.displayName = "ButtonLink"

export default ButtonLink
