import { SerializedError } from "@reduxjs/toolkit"
import { BaseQueryFn } from "@reduxjs/toolkit/query/react"
import axios, { AxiosRequestConfig } from "axios"

import { buildSerializedError } from "../../lib/buildSerializedError"
import reportAxiosError, { ReportContext } from "../../lib/reportAxiosError"
import { RootState } from "../store"

export default function axiosBaseQuery(
  {
    baseUrl,
  }: {
    baseUrl: string
  } = {
    baseUrl: "",
  }
): BaseQueryFn<
  {
    url: string
    params?: AxiosRequestConfig["params"]
    method?: AxiosRequestConfig["method"]
    requestData?: AxiosRequestConfig["data"]
    headers?: AxiosRequestConfig["headers"]
    reportContext?: ReportContext
    sendIdent?: boolean
  },
  unknown,
  SerializedError
> {
  return async (
    {
      url,
      params,
      requestData,
      method = "GET",
      headers,
      reportContext,
      sendIdent = false,
    },
    api
  ) => {
    try {
      let mergedParams = params
      let mergedData = requestData
      if (sendIdent && !(api.getState() as RootState).auth.isAuth) {
        const state = api.getState() as RootState
        switch (method) {
          case "POST":
            mergedData = {
              ...requestData,
              token: state.persistant.session.identToken,
              user_id: state.persistant.session.userId,
            }
            break
          case "GET":
            mergedParams = {
              ...params,
              token: state.persistant.session.identToken,
              user_id: state.persistant.session.userId,
            }
            break
        }
      }
      const { data }: { data: unknown } = await axios({
        url: baseUrl + url,
        method,
        params: mergedParams,
        data: mergedData,
        headers,
      })
      return { data }
    } catch (err) {
      reportAxiosError(err, reportContext)
      return { error: buildSerializedError(err, reportContext) }
    }
  }
}
