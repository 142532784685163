import { Field, FormikErrors, FormikTouched, FormikValues } from "formik"
import { FieldConfig } from "formik/dist/Field"

import { InputType } from "../../../constants/input.types"

interface Props extends Partial<FieldConfig> {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  slug: string
  type?: InputType
  errorSlug?: string
}

const AccessibleFormikFormField = ({
  errors,
  touched,
  slug,
  errorSlug,
  type,
}: Props) => {
  return (
    <Field
      aria-invalid={errors[slug] && touched[slug] ? "true" : null}
      aria-describedby={errors[slug] && touched[slug] ? errorSlug : null}
      aria-required="true"
      type={type}
      name={slug}
      id={slug}
      error={errorSlug}
      errors={errors}
      touched={touched}
    />
  )
}

export default AccessibleFormikFormField
