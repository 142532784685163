import React from "react"

import ContentBox from "../ContentBox"
import s from "./PropertyBox.module.scss"

const PropertyBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={s.info}>
      <ContentBox noFullWidth topMargin>
        {children}
      </ContentBox>
    </div>
  )
}

export default PropertyBox
