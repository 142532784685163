import cx from "classnames"

import Button, { ButtonFill, ButtonStyle } from "./Button"
import s from "./DesktopBurgerButton.module.scss"

type Props = {
  open: boolean
  toggleMenu: () => void
  hide?: boolean
}

const DesktopBurgerButton = ({ open, toggleMenu, hide }: Props) => {
  return (
    <Button
      onClick={toggleMenu}
      className={cx(s.container, { [s.hidden]: hide })}
      style={ButtonStyle.NONE}
      fill={ButtonFill.NONE}
    >
      <div className={s.upper} />
      <div className={cx(s.middle, { [s.closed]: !open })} />
      <div className={cx(s.bottom, { [s.closed]: !open })} />
    </Button>
  )
}

export default DesktopBurgerButton
