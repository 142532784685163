import NoDataImg from "../../../images/no-data/emptyDataImg.png"
import classes from "./EmptyData.module.scss"

interface Props {
  text?: string
}

const EmptyDataPlaceholder = ({ text }: Props) => {
  return (
    <div className={classes.container} data-testid="chart-placeholder">
      <img alt="empty graph data" src={NoDataImg} />
      <span className={classes.text}>{text}</span>
    </div>
  )
}

export default EmptyDataPlaceholder
