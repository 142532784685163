import runtimeEnv from "@mars/heroku-js-runtime-env"
import axios from "axios"
import { Suspense, lazy, useRef, useEffect, memo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router"
import { ToastContainer } from "react-toastify"
import "@fontsource-variable/mulish"
import "@fontsource-variable/mulish/wght-italic.css"
import "react-toastify/dist/ReactToastify.css"

import routes from "../constants/routes"
import { BlocksOverlayProvider } from "../hooks/blocks/BlocksOverlayProvider"
import useSentryUserScope from "../hooks/useSentryUserScope"
import configureToastTransition from "../lib/configureToastTransition"
import retry from "../lib/retry"
import { handleURLSearchParams } from "../lib/searchParams"
import { updateRedirect } from "../redux/actions/route"
import { getUser } from "../redux/actions/user"
import ContentApiStatusWrapper from "../redux/services/content/ContentApiStatusWrapper"
import { updateSession } from "../redux/slices/persistantSession"
import { useAppSelector } from "../redux/store"
import { AuthExpiry, Container, ErrorMessage } from "./common"
import "../lib/dayjsConfig"
import "../style/app.scss"
import { BlocksOverlay } from "./common/BlocksOverlay"
import ContentHighlightingButton from "./common/ContentBlock/ContentHighlightingButton/ContentHighlightingButton"
import LoadingPage from "./ConsumerSide/routes/LoadingPage"

const LazyBusinessSide = lazy(() => retry(() => import("./BusinessSide")))
const LazyConsumerSide = lazy(() => retry(() => import("./ConsumerSide")))

const env = runtimeEnv()
axios.defaults.baseURL = env.REACT_APP_BACKEND_API_END_POINT
// process.env is deliberately used for SOURCE_VERSION (see craco.config.ts)
// this should be built into the bundle on Staging
// then when the slug is moved to Production, it should be the same
if (process.env.SOURCE_VERSION) {
  axios.defaults.headers["x-frontend-version"] = process.env.SOURCE_VERSION
}

export default memo(function App() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const businessSide = useAppSelector((state) => state.auth.businessSide)
  const firm = useAppSelector((state) => state.auth.firm)
  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const errorMessage = useAppSelector((state) => state.auth.errorMessage)

  const previousAuth = useRef<boolean | undefined>(undefined)

  useSentryUserScope()

  if (!env.REACT_APP_BACKEND_API_END_POINT) {
    throw Error("API backend not defined")
  }

  const [init, setInit] = useState(true)

  useEffect(() => {
    if (init) {
      setInit(false)
      const {
        authToken,
        identToken,
        userId,
        mortgageId,
        email,
        isCreatingPassword,
        resetToken,
      } = handleURLSearchParams(history, location.search)
      dispatch(
        updateSession({
          authToken,
          userId,
          identToken,
          mortgageId,
          email,
          isCreatingPassword,
          resetToken,
          init: true,
        })
      )
      dispatch(getUser())
    }
  }, [dispatch, history, location.search, init])

  useEffect(() => {
    if (previousAuth.current && !isAuth) {
      /* On Logout */
      dispatch(getUser())
      history.push(routes.HOME)
      dispatch(updateRedirect(undefined))
    }
    previousAuth.current = isAuth
  }, [isAuth, dispatch, history])

  let app: JSX.Element | undefined

  if (businessSide) {
    app = <LazyBusinessSide />
  } else if (firm) {
    app = <LazyConsumerSide />
  }

  if (app) {
    return (
      <ContentApiStatusWrapper>
        <BlocksOverlayProvider>
          <BlocksOverlay />
          <Suspense fallback={<LoadingPage />}>{app}</Suspense>
          <AuthExpiry />
          <ToastContainer
            hideProgressBar={true}
            position={"bottom-left"}
            autoClose={3000}
            closeButton={false}
            closeOnClick={false}
            transition={configureToastTransition()}
          />
          <ContentHighlightingButton />
        </BlocksOverlayProvider>
      </ContentApiStatusWrapper>
    )
  } else if (errorMessage) {
    return (
      <Container bg="transparent">
        <ErrorMessage msg={errorMessage} includeReloadMsg />
      </Container>
    )
  }

  return null
})
