import cx from "classnames"

import { RadioOption } from "../../types/retain"
import s from "./OptionSelector.module.scss"

interface Props<T> {
  change: (value: T) => void
  idPrefix: string
  options: RadioOption<T>
  selectedOption: T
  altStyle?: boolean
}

function OptionSelector<T>(props: Props<T>): JSX.Element {
  const { change, idPrefix, options, selectedOption, altStyle = false } = props

  return (
    <div className={s.container}>
      {Object.entries(options).map(([key, option]) => {
        const id = `${idPrefix}_${key}`
        return (
          <div className={cx(s.optionsRadio, { [s.altStyle]: altStyle })} key={key}>
            <label htmlFor={id}>
              <input
                className={s.optionSelector}
                id={id}
                type="checkbox"
                checked={selectedOption === option.value}
                onChange={(): void => {
                  change(option.value)
                }}
              />
              <span className={s.option}>{option.label}</span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default OptionSelector
