import cx from "classnames"

import { ReactComponent as Cross1x } from "../../../images/Cross1x.svg"
import { ReactComponent as Lock1x } from "../../../images/Lock1x.svg"
import { ReactComponent as Tick1x } from "../../../images/Tick1x.svg"
import { handleEnterPressed } from "../../../lib/keyboardEvents"
import s from "./Toggle.module.scss"
import "./Toggle.css"

interface Props {
  isOn: boolean
  secondaryColor?: boolean
  ariaLabel?: string
  labelledBy?: string
  onToggle?: (arg0: boolean) => void
  disabled?: boolean
  leftAligned?: boolean
}

const Toggle = ({
  isOn,
  onToggle,
  ariaLabel,
  labelledBy,
  secondaryColor,
  disabled,
  leftAligned,
}: Props) => {
  return (
    <div
      className={`${cx(s.toggle, {
        [s.on]: isOn,
        [s.secondaryColor]: secondaryColor,
        [s.disabled]: disabled,
        [s.leftAligned]: leftAligned,
        secondaryColor,
      })}`}
      tabIndex={0}
      role="checkbox"
      aria-label={ariaLabel}
      aria-labelledby={labelledBy}
      aria-checked={isOn}
      onClick={() => onToggle && !disabled && onToggle(!isOn)}
      onKeyDown={handleEnterPressed(() => onToggle && !disabled && onToggle(!isOn))}
    >
      <div className={s.icon}>
        {disabled ? <Lock1x /> : isOn ? <Tick1x /> : <Cross1x />}
      </div>
    </div>
  )
}

export default Toggle
