import cx from "classnames"
import { forwardRef } from "react"

import useTrackClick from "../../../hooks/trackClick"
import { handleEnterPressed } from "../../../lib/keyboardEvents"
import Loading from "../Loading"
import s from "./Button.module.scss"
import {
  ButtonFill,
  ButtonProps,
  ButtonSize,
  ButtonStyle,
  ButtonType,
} from "./Button.types"

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      children,
      className = "",
      fullWidth,
      icon,
      label,
      disabled = false,
      loading = false,
      onClick,
      onEnter,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      style = ButtonStyle.SOLID,
      fill = ButtonFill.PRIMARY,
      size = ButtonSize.DEFAULT,
      type = ButtonType.BUTTON,
      trackingId,
      reverseIconPosition,
      trackingLabel,
      topMargin,
    },
    ref
  ) => {
    const { trackClick } = useTrackClick()
    const handleClick = (e?: React.MouseEvent | React.KeyboardEvent): void => {
      if (trackingLabel && trackingId) {
        trackClick(`${trackingLabel} - ${trackingId}`)
      } else if (trackingId) {
        trackClick(trackingId)
      }
      onClick?.(e)
    }

    function handleDisabledEvent(e?: React.MouseEvent | React.KeyboardEvent) {
      e?.preventDefault()
    }

    return (
      <button
        ref={ref}
        className={cx(`${s.button}`, s[size], style, fill, {
          [className]: className,
          [s.full]: fullWidth,
          [s.topMargin]: topMargin,
        })}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={(e) => (disabled ? handleDisabledEvent : handleClick(e))}
        onKeyDown={
          disabled ? handleDisabledEvent : handleEnterPressed(onEnter || handleClick)
        }
        type={type}
        aria-label={label || trackingId}
        aria-disabled={disabled}
        id={id}
      >
        {loading ? (
          <div className={s.loading}>
            <Loading />
          </div>
        ) : (
          <>
            {children}
            <div
              className={cx(s.icon, {
                [s.reverse]: reverseIconPosition,
              })}
            >
              {icon}
            </div>
          </>
        )}
      </button>
    )
  }
)

Button.displayName = "Button"

export default Button
