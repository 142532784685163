import dayjs from "dayjs"

import useMdxContent_Unstable from "../../hooks/mdx/useMdxContent_Unstable"
import formatInitialTerm from "../../lib/formatInitialTerm"
import { useAppSelector } from "../../redux/store"
import "../../lib/dayjsConfig"
import { CurrentSituationContentId } from "../../types/retain/Content.types"
import { loanPurpose, Mortgage } from "../../types/retain/Mortgages.types"
import { CurrentSituationPagePaths } from "../consumer/LenderExpiryPage/currentSituationPageBlocks"

interface Props {
  mortgage: Mortgage
  owned?: boolean
  isOverSixMonths?: boolean
}

const CurrentSituationPreamble = ({
  mortgage,
  owned = false,
  isOverSixMonths = true,
}: Props) => {
  const isPortal = useAppSelector((state) => state.auth.firm?.isPortal)

  const isAdditionalMortgage =
    mortgage.loanPurpose === loanPurpose.FURTHER_ADVANCE ||
    mortgage.loanPurpose === loanPurpose.ADDITIONAL_BORROWING

  if (isPortal) {
    return <LenderPreamble mortgage={mortgage} owned={owned} />
  }

  if (mortgage.expired) {
    return (
      <p>
        The initial rate of {owned ? "your" : "their"}
        {isAdditionalMortgage && " additional"} mortgage{" "}
        {mortgage?.lender && mortgage.lender.name ? (
          <>with {mortgage.lender.name} </>
        ) : (
          ""
        )}
        has expired.
        {owned ? " You've" : " They've"} now been paying a higher rate for{" "}
        {dayjs(mortgage.expiryDate).fromNow(true)}.
      </p>
    )
  }

  if (isOverSixMonths) {
    return (
      <p>
        {owned ? "Your" : "Their"}
        {mortgage?.lender && mortgage.lender.name ? <> {mortgage.lender.name} </> : ""}
        mortgage has an initial term of{" "}
        {formatInitialTerm(mortgage.expiryDate, mortgage.startDate)}. The initial term
        ends {dayjs(mortgage.expiryDate).fromNow()}.
      </p>
    )
  }

  return (
    <p>
      {owned ? "Your" : "Their"}
      {mortgage?.lender && mortgage.lender.name ? <> {mortgage.lender.name} </> : ""}
      mortgage has an initial term of{" "}
      {formatInitialTerm(mortgage.expiryDate, mortgage.startDate)}.
    </p>
  )
}

function LenderPreamble({ mortgage, owned }: Props) {
  const contentIdPath = mortgage.expired
    ? CurrentSituationContentId.PREAMBLE_EXPIRED_MORTGAGE_MDX
    : CurrentSituationContentId.PREAMBLE_ACTIVE_MORTGAGE_MDX

  const content = useMdxContent_Unstable<CurrentSituationPagePaths>(contentIdPath, {
    pronounLowercase: owned ? "your" : "their",
    pronounCapitalized: owned ? "Your" : "Their",
    expiryDate: dayjs(mortgage.expiryDate).fromNow(),
  })

  return <>{content}</>
}

export default CurrentSituationPreamble
