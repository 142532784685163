import dayjs from "dayjs"
import { ReactElement } from "react"
import Countdown, { zeroPad } from "react-countdown"

import BasicModal from "../BasicModal"
import useAuthExpiry from "./useAuthExpiry"

function AuthExpiry() {
  const { authExpiry, shown, dismiss } = useAuthExpiry()

  return (
    <BasicModal
      title="Your session is about to expire"
      buttons={[
        {
          children: "Keep me logged in",
          onClick: dismiss,
        },
      ]}
      hide={dismiss}
      shown={shown}
    >
      <>
        <p>
          For your security, we'll log you out in{" "}
          <Countdown
            date={dayjs(authExpiry).format()}
            renderer={({ minutes, seconds }): ReactElement => (
              <>
                {minutes > 0
                  ? `${zeroPad(minutes)}:${zeroPad(seconds)} minutes. `
                  : `${zeroPad(seconds)} seconds. `}
              </>
            )}
          />
          Do you wish to stay logged in?
        </p>
      </>
    </BasicModal>
  )
}

export default AuthExpiry
