import axios from "axios"
import cx from "classnames"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useHistory } from "react-router"

import { InputType } from "../../../constants/input.types"
import routes from "../../../constants/routes"
import useSetError from "../../../hooks/useSetError"
import s from "../../../style/common/input-validation.module.scss"
import { FormValues } from "../../../types/retain"
import AccessibleFormikFormField from "../AccessibleFormikFormField"
import Button from "../Button"
import Container from "../Container"
import FormError from "../FormError"
import PasswordStrengthMeter from "../PasswordStrengthMeter"
import { toastNotification } from "../ToastNotification"
import { FormikOnSubmitProps, ValidatePasswordErrors } from "./ChangePassword.types"

enum FormSlugs {
  CURRENT_PASSWORD = "currentPassword",
  NEW_PASSWORD = "newPassword",
  PASSWORD_CONFIRMATION = "passwordConfirmation",
}

enum FormErrors {
  CURRENT_PASSWORD_ERROR = "currentPasswordError",
  NEW_PASSWORD_ERROR = "newPasswordError",
  PASSWORD_CONFIRMATION_ERROR = "passwordConfirmationError",
}

export function validatePassword(values: FormValues) {
  const errors: ValidatePasswordErrors = {}
  if (!values.newPassword) {
    errors.newPassword = "The new password can't be blank."
  } else if (values.newPassword !== values.passwordConfirmation) {
    errors.passwordConfirmation =
      "The password confirmation should match the new password."
  }
  return errors
}

const ChangePassword = () => {
  const history = useHistory()
  const setError = useSetError()
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [newPassword, setNewPassword] = useState<string | undefined>("")

  const validate = (values: FormValues) => {
    setNewPassword(values.newPassword)
    const errors: ValidatePasswordErrors = {}
    if (!values.currentPassword) {
      errors.currentPassword = "Your current password can't be blank."
    }
    Object.assign(errors, validatePassword(values))
    return errors
  }

  const submit = (
    values: FormValues,
    { setSubmitting, setErrors }: FormikOnSubmitProps
  ) => {
    setPasswordChanged(false)
    axios
      .post("/api-v1/change-password/", values)
      .then((response) => {
        if (response.data.passwordChanged) {
          history.push(routes.HOME)
          toastNotification("Your password has been changed.", "info")
          setPasswordChanged(true)
        } else {
          setErrors(response.data.error)
        }
        setSubmitting(false)
      })
      .catch(setError)
  }

  return (
    <Container bg="transparent">
      <div className="action-box change-password">
        <h1>Change password</h1>
        {passwordChanged && <p>Your password has been changed.</p>}
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            passwordConfirmation: "",
          }}
          validate={validate}
          onSubmit={submit}
        >
          {({ errors, touched, isSubmitting, isValid, dirty, submitForm }) => {
            const disabled = isSubmitting || !isValid || !dirty
            return (
              <Form>
                <div
                  className={cx(s.inputGroup, {
                    [s.errorInput]: !!errors.currentPassword && touched.currentPassword,
                  })}
                >
                  <label htmlFor={FormSlugs.CURRENT_PASSWORD}>Current password:</label>
                  <AccessibleFormikFormField
                    errors={errors}
                    touched={touched}
                    type={InputType.PASSWORD}
                    slug={FormSlugs.CURRENT_PASSWORD}
                    errorSlug={FormErrors.CURRENT_PASSWORD_ERROR}
                  />
                  <FormError
                    name={FormSlugs.CURRENT_PASSWORD}
                    id={FormErrors.CURRENT_PASSWORD_ERROR}
                  />
                </div>
                <div
                  className={cx(s.inputGroup, {
                    [s.errorInput]: !!errors.newPassword && touched.newPassword,
                  })}
                >
                  <label htmlFor={FormSlugs.NEW_PASSWORD}>New password:</label>
                  <AccessibleFormikFormField
                    errors={errors}
                    touched={touched}
                    type={InputType.PASSWORD}
                    slug={FormSlugs.NEW_PASSWORD}
                    errorSlug={FormErrors.NEW_PASSWORD_ERROR}
                  />
                  <PasswordStrengthMeter newPassword={newPassword as string} />
                  <FormError
                    name={FormSlugs.NEW_PASSWORD}
                    id={FormErrors.NEW_PASSWORD_ERROR}
                  />
                </div>
                <div
                  className={cx(s.inputGroup, {
                    [s.errorInput]:
                      !!errors.passwordConfirmation && touched.passwordConfirmation,
                  })}
                >
                  <label htmlFor={FormSlugs.PASSWORD_CONFIRMATION}>
                    Password confirmation:
                  </label>
                  <AccessibleFormikFormField
                    errors={errors}
                    touched={touched}
                    type={InputType.PASSWORD}
                    slug={FormSlugs.PASSWORD_CONFIRMATION}
                    errorSlug={FormErrors.PASSWORD_CONFIRMATION_ERROR}
                  />
                  <FormError
                    name={FormSlugs.PASSWORD_CONFIRMATION}
                    id={FormErrors.PASSWORD_CONFIRMATION_ERROR}
                  />
                </div>
                <Button onClick={submitForm} disabled={disabled}>
                  Change password
                </Button>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Container>
  )
}

export default ChangePassword
