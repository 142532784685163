import cx from "classnames"

import { ReactComponent as TickNoCircle } from "../../images/TickNoCircle.svg"
import s from "./Checkbox.module.scss"

interface Props {
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  id: string
  label?: React.ReactNode | string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  // Note: the original styling may become superfluous once consumer side checkboxes become toggles.
  altStyle?: boolean
  baseline?: boolean
  classNames?: {
    input?: string
    label?: string
    icon?: string
  }
}

function Checkbox(props: Props): JSX.Element {
  const {
    checked,
    defaultChecked,
    disabled = false,
    id,
    label,
    onChange = (): void => {},
    altStyle = false,
    baseline = false,
    classNames,
  } = props

  return (
    <label
      className={cx({
        checkbox: true,
        [s.altContainer]: altStyle,
        [s.altContainerChecked]: altStyle && checked,
      })}
      data-testid="checkbox_label"
      htmlFor={id}
      key={`checkbox_${id}`}
    >
      <input
        checked={checked === null ? undefined : checked}
        className={cx(s.input, classNames?.input)}
        data-testid="checkbox_input"
        defaultChecked={defaultChecked === null ? undefined : defaultChecked}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
      <span
        className={cx(s.label, classNames?.label, {
          [s.altLabel]: altStyle,
          [s.baseline]: baseline,
        })}
      >
        <TickNoCircle
          className={cx(s.icon, classNames?.icon, { [s.altIcon]: altStyle })}
        />
        {label}
      </span>
    </label>
  )
}

export default Checkbox
