import { useEffect, useRef } from "react"
import Confetti from "react-dom-confetti"

import s from "./Confetties.module.scss"

function usePrevious(value: boolean) {
  const ref = useRef<boolean>()
  useEffect(() => {
    ref.current = value
  })
  // This will return on each render and then the useEffect with no deps array will run post-render to update
  return ref.current
}

const confettiConfig = {
  angle: 102,
  spread: 350,
  startVelocity: 34,
  elementCount: 200,
  dragFriction: 0.09,
  delay: 2,
  width: "11px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

type ConfettiesProps = {
  confetti: boolean
  reloadConfetti: () => void
  duration?: number
}

function Confetties({ confetti, reloadConfetti, duration = 2000 }: ConfettiesProps) {
  const timeoutId = useRef<number>()
  const previousConfetti = usePrevious(confetti)

  useEffect(() => {
    if (confetti && !previousConfetti) {
      timeoutId.current = window.setTimeout(reloadConfetti, duration)
    }
    /* TODO: ENG-5731 Remove eslint-disable for dependency arrays in hooks */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confetti])

  useEffect(() => {
    return () => {
      reloadConfetti()
      clearTimeout(timeoutId.current)
    }
    /* TODO: ENG-5731 Remove eslint-disable for dependency arrays in hooks */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={confetti ? s.celebration : undefined}>
      <Confetti active={confetti} config={{ ...confettiConfig, duration }} />
    </div>
  )
}

export default Confetties
