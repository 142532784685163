import { LabelPaths } from "../../../hooks/blocks/util.types"

const basedOnMdxDefault = `Your monthly payment is calculated using an estimated remaining mortgage amount. The estimated remaining mortgage amount assumes you have made no extra payments on your mortgage.
The standard variable rate (SVR) we use is an average across the products that your lender offers. Your SVR may be lower or higher than this rate.
`
const whyHappeningMdxDefault = `<p>The mortgage you took with us in {props.startDate} has an initial term of <strong>{props.initialTermInYears} years</strong>. Your mortgage started on a rate of {props.preferentialRate} moving to a Follow - on Rate of {props.followOnRate} at the end of your initial term.</p><p>Your initial term <strong>ends on {props.expiryDate}</strong>, which is why your payment is changing.</p>`

export const mortgageOverviewBlocks = {
  followOnPayments: {
    basedOnExpiredMdxContent: {
      type: "mdx",
      default: basedOnMdxDefault,
      description:
        "Paragraph used to display monthly payment information for expired mortgages",
    },
    basedOnActiveMdxContent: {
      type: "mdx",
      default: basedOnMdxDefault,
      description:
        "Paragraph used to display monthly payment information for active mortgages",
    },
    rateTableHeaderContent: {
      type: "text",
      default: "Follow on rate",
      description: "Header for the follow on payments table rate column",
    },
  },
  whyPaymentChangePostExpiry: {
    type: "text",
    description:
      "The link that opens up the modal that explains why payments changed (for an expired mortgage) on the Current Situation page",
    default: "Why did my payments change",
  },
  whyPaymentChangePreExpiry: {
    type: "text",
    description:
      "The link that opens up the modal that explains why payments are changing (for an active mortgage) on the Current Situation page",
    default: "Why are my payments changing",
  },
  mortgageOverview: {
    popIn: {
      titleContent: {
        type: "text",
        description:
          "Title of the modal that pops up when clicking the link on the Current Situation page for Lenders",
        default: "Why is this happening?",
      },
    },
    whyHappening: {
      image: {
        type: "image",
        description:
          "Image displayed in the modal that pops up when clicking the link on the Current Situation page for Lenders",
        default: null,
      },
      mdxContent: {
        type: "mdx",
        description:
          "Content displayed in the modal that pops up when clicking the link on the Current Situation page for Lenders",
        default: whyHappeningMdxDefault,
      },
    },
  },
}

export type MortgageOverviewPaths = LabelPaths<typeof mortgageOverviewBlocks>
