import { merge } from "lodash"

import { changeDataPopInBlocks } from "../../components/business/ChangeDataPopIn/changeDataPopInBlocks"
import { loadApprovalBlocks } from "../../components/business/LoadApproval/loadApprovalBlocks"
import { onboardingBlocks } from "../../components/business/Onboarding/onboardingBlocks"
import { quickSelectBlocks } from "../../components/business/QuickSelect/quickSelectBlocks"
import { cookiePolicyBlocks } from "../../components/common/CookiePolicyPopover/cookiePolicyBlocks"
import { mortgageOverviewBlocks } from "../../components/common/MortgageOverview/mortgageOverviewBlocks"
import { alreadyActionedFormBlocks } from "../../components/consumer/AlreadyActionedForm/alreadyActionedFormBlocks"
import { askForHelpBlocks } from "../../components/consumer/AskForHelp/askForHelpBlocks"
import { blogBlocks } from "../../components/consumer/BlogPosts/BlogPost/blogBlocks"
import { portalArticleBlocks } from "../../components/consumer/BlogPosts/PortalArticle/portalArticleBlocks"
import { kbaLoginBlocks } from "../../components/consumer/KbaLogin/kbaLoginBlocks"
import { lenderCTAPagesBlocks } from "../../components/consumer/LenderCTAPages/lenderCTAPagesBlocks"
import { seeOptionsBoxInformedConsumersBlocks } from "../../components/consumer/LenderExpiryPage/ContentBoxes/SeeOptions/seeOptionsBoxInformedConsumersBlocks"
import { currentSituationPageBlocks } from "../../components/consumer/LenderExpiryPage/currentSituationPageBlocks"
import { externalAdvicePageBlocks } from "../../components/consumer/LenderExternalAdvicePage/externalAdvicePageBlocks"
import { getHelpPageBlocks } from "../../components/consumer/LenderGetHelpPage/getHelpPageBlocks"
import { internalAdvicePageBlocks } from "../../components/consumer/LenderInternalAdvicePage/internalAdvicePageBlocks"
import { lenderOptionsPageBlocks } from "../../components/consumer/LenderOptionsPage/lenderOptionsPageBlocks"
import { optionsInformedConsumersBlocks } from "../../components/consumer/LenderOptionsPage/OptionsCards/optionsInformedConsumersBlocks"
import { remindProductsPageBlocks } from "../../components/consumer/LenderRemindProductsPage/remindProductsPageBlocks"
import { withoutAdvicePageBlocks } from "../../components/consumer/LenderWithoutAdvicePage/withoutAdvicePageBlocks"
import { withSwitchSupportPageBlocks } from "../../components/consumer/LenderWithSwitchSupportPage/withSwitchSupportPageBlocks"
import { expiryDatePageBlocks } from "../../components/consumer/Lifecycle/ExpiryDatePage/expiryDatePageBlocks"
import { interestRatesPageBlocks } from "../../components/consumer/Lifecycle/InterestRatesPage/interestRatesPageBlocks"
import { propertyHealthBlocks } from "../../components/consumer/Lifecycle/LifecyclePage/propertyHealthBlocks"
import { mortgagesHomeBlocks } from "../../components/consumer/Lifecycle/MortgageHomePage/mortgagesHomeBlocks"
import { productRatesPageBlocks } from "../../components/consumer/Lifecycle/ProductRatesPage/productRatesPageBlocks"
import { propertyValuePageBlocks } from "../../components/consumer/Lifecycle/PropertyPricePage/propertyValuePageBlocks"
import { menuBlocks } from "../../components/consumer/Menu/menuBlocks"
import { zeroMortgagesBlock } from "../../components/consumer/NoMortgagesContentBlock/zeroMortgagesBlock"
import { notInterestedPageBlocks } from "../../components/consumer/NotInterested/notInterestedPageBlocks"
import { policiesBlocks } from "../../components/consumer/PoliciesTab/policiesBlocks"
import { portalButtonBlocks } from "../../components/consumer/PortalButtons/portalButtonBlocks"
import { acceptanceModalsBlocks } from "../../components/consumer/PortalButtons/portalButtonModals/AcceptanceModalsBlocks"
import { portalNavBlocks } from "../../components/consumer/PortalNav/portalNavBlocks"
import { productSelectionInfoModalsBlocks } from "../../components/consumer/ProductSelection/CardInfoModals/productSelectionInfoModalsBlocks"
import { productSelectionInfoPageBlocks } from "../../components/consumer/ProductSelection/ProductInfo/productSelectionInfoPageBlocks"
import { productSelectionListPageBlocks } from "../../components/consumer/ProductSelection/ProductSelectionListPage/productSelectionListPageBlocks"
import { productSelectionStartPageBlocks } from "../../components/consumer/ProductSelection/ProductSelectionStartPage/productSelectionStartPageBlocks"
import { productSelectionSummaryPageBlocks } from "../../components/consumer/ProductSelection/ProductSelectionSummaryPage/productSelectionSummaryPageBlocks"
import { thanksPageBlocks } from "../../components/consumer/ProductSelection/ProductSelectionThanksPage/thanksPageBlocks"
import { thanksModalBlocks } from "../../components/consumer/ThanksModal/thanksModalBlocks"
import { unsubscribePageBlocks } from "../../components/consumer/Unsubscribe/unsubscribePageBlocks"
import { styleBlocks } from "../../lib/styleBlocks"
import { lenderOptionsBlocks } from "../lenderOptionsBlocks"
import { infoBoxesBlocks } from "../mdx/defaults/infoBoxesBlocks"

export const blocks = merge(
  {},
  acceptanceModalsBlocks,
  alreadyActionedFormBlocks,
  askForHelpBlocks,
  blogBlocks,
  changeDataPopInBlocks,
  cookiePolicyBlocks,
  currentSituationPageBlocks,
  expiryDatePageBlocks,
  externalAdvicePageBlocks,
  getHelpPageBlocks,
  infoBoxesBlocks,
  interestRatesPageBlocks,
  internalAdvicePageBlocks,
  kbaLoginBlocks,
  lenderCTAPagesBlocks,
  lenderOptionsBlocks,
  lenderOptionsPageBlocks,
  loadApprovalBlocks,
  menuBlocks,
  mortgageOverviewBlocks,
  mortgagesHomeBlocks,
  notInterestedPageBlocks,
  onboardingBlocks,
  optionsInformedConsumersBlocks,
  policiesBlocks,
  portalArticleBlocks,
  portalButtonBlocks,
  portalNavBlocks,
  productRatesPageBlocks,
  productSelectionInfoModalsBlocks,
  productSelectionInfoPageBlocks,
  productSelectionListPageBlocks,
  productSelectionStartPageBlocks,
  productSelectionSummaryPageBlocks,
  propertyValuePageBlocks,
  propertyHealthBlocks,
  quickSelectBlocks,
  remindProductsPageBlocks,
  seeOptionsBoxInformedConsumersBlocks,
  styleBlocks,
  thanksModalBlocks,
  thanksPageBlocks,
  unsubscribePageBlocks,
  withoutAdvicePageBlocks,
  withSwitchSupportPageBlocks,
  zeroMortgagesBlock
)

type ReplacesList = { source: string; destination: string }[]
// Convention is to write the replaces data object in files near point of use (like blocks above) and then import for use here.
export const replaces: ReplacesList = []
