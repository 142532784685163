import * as Sentry from "@sentry/react"
import { Component } from "react"
import { Helmet } from "react-helmet-async"

import { ReactComponent as ErrorIllustration } from "../../images/ErrorIllustration.svg"
import s from "./ErrorBoundary.module.scss"

type State = {
  hasError: boolean
}

class ErrorBoundary extends Component<{}, State> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError = () => ({ hasError: true })

  componentDidCatch = (error: Error) => {
    Sentry.captureException(error)
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <div className={s.errorBoundary}>
          <Helmet>
            <title>Error</title>
          </Helmet>
          <div className="action-box">
            <ErrorIllustration className={s.errorSvg} />
            <h2>Whoops there’s no one here</h2>
            <p>
              There was an unexpected error. We have been notified of this issue and
              we&rsquo;ll work on it.
            </p>
            <p>Reloading the page might clear the error and allow you to continue.</p>
            <button
              type="button"
              className={s.refreshButton}
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </div>
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
