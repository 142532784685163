import cx from "classnames"
import qs from "query-string"
import { ReactElement } from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"

import { DEFAULT_LIST_SIZE } from "../../../constants/retain"
import { getPageCount } from "../../../lib/pagination"
import s from "./Paginate.module.scss"

interface Props {
  location: RouteComponentProps["location"]
  match: RouteComponentProps["match"]
  current: number | null
  total: number
  className?: string
}

const getPageLocation = (
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"],
  page: number
): string => {
  const query = qs.stringify({
    ...qs.parse(location.search),
    page: page === 1 ? undefined : page,
  })
  return match == null ? `?${query}` : `${match.path}?${query}`
}

const Ellipsis = () => <li className={`${s.item} ${s.ellipsis}`}>...</li>

const getListFromMapping = (
  mapping: Array<number | string>,
  current: number,
  location: RouteComponentProps["location"],
  match: RouteComponentProps["match"]
): ReactElement[] =>
  mapping.map((i) => {
    if (typeof i === "string") return <Ellipsis key={i} />

    const path = getPageLocation(location, match, i)

    return (
      <li key={i} className={current === i ? s.itemSelected : s.item}>
        <Link
          className={s.link}
          to={path}
          aria-current={current === i ? "page" : undefined}
        >
          {i}
        </Link>
      </li>
    )
  })

const Paginate = ({ className, current, location, match, total }: Props) => {
  const count = getPageCount(total, DEFAULT_LIST_SIZE)

  if (count < 2 || current == null) return null

  let mapping: Array<number | string>

  if (count > 6) {
    if (current < 3 || current === count || current === count - 1) {
      mapping = [1, 2, 3, "e1", count - 2, count - 1, count]
    } else if (current === 3) {
      mapping = [1, 2, 3, 4, "e1", count - 1, count]
    } else if (current === count - 2) {
      mapping = [1, 2, "e1", count - 3, count - 2, count - 1, count]
    } else {
      mapping = [1, "e1", current - 1, current, current + 1, "e2", count]
    }
  } else {
    mapping = new Array(count).fill(1).map((val, index) => val + index)
  }

  return (
    <nav className={s.root} role="navigation">
      <ol className={cx(s.list, className)}>
        {getListFromMapping(mapping, current, location, match)}
      </ol>
    </nav>
  )
}

export default Paginate
