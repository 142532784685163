import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "./axiosBaseQuery"

type ResponseType = {
  eventType: string
  extraData?: unknown
}
export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    sendEvent: builder.mutation<null, ResponseType>({
      query: ({ eventType, extraData }) => ({
        url: `/event/`,
        method: "POST",
        requestData: {
          eventType,
          extraData,
        },
      }),
    }),
  }),
})

export const { useSendEventMutation } = eventApi
