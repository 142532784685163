/** If passed as a number then this is used as the index of where to split
 * the string. If passed as a function then this is run with `children` as
 * its first parameter and should return the index to split the string at. If
 * `-1` is given (the default) or returned from the function then `children`
 * is not split and the result is an ellipsis at the end of the line */
export type EllipsisAt = ((str: string) => number) | number

export default function splitString(
  str: string,
  getIndex: EllipsisAt
): [string, string] {
  let index: number
  if (typeof getIndex === "function") {
    index = getIndex(str)
  } else {
    index = getIndex
  }

  if (index === -1) return [str, ""]

  return [str.slice(0, index), str.slice(index)]
}
