import cx from "classnames"
import { isNil } from "lodash-es"
import { useState } from "react"
import { useHistory } from "react-router-dom"

import buttonTrackingIds from "../../constants/buttonTrackingIds"
import useTrackClick from "../../hooks/trackClick"
import _cookieConsent, { Consent } from "../../lib/cookie-consent"
import { useAppSelector } from "../../redux/store"
import AnimateHeight from "./AnimateHeight"
import Button from "./Button"
import ContentBox from "./ContentBox"
import s from "./CookiePolicyText.module.scss"
import Toggle from "./Toggle"

const CookiePolicyText = () => {
  const [cookieConsent, setCookieConsent] = useState<Consent | null>(_cookieConsent())
  const { trackClick } = useTrackClick()
  const history = useHistory()
  const firmLegalName = useAppSelector((state) => state.auth.firm?.legalName)
  const firmName = useAppSelector((state) => state.auth.firm?.name)
  const functional = isNil(cookieConsent) ? true : cookieConsent.functional

  return (
    <>
      <ContentBox className={s.root} topMargin={false}>
        <AnimateHeight in={cookieConsent === null}>
          <h2>Cookie policy</h2>
          <p>
            You can agree to our use of Cookies by clicking &ldquo;accept all&rdquo; or,
            if you prefer, you can customise your preferences below.
          </p>
          <Button
            className={s.acceptAll}
            fullWidth
            onClick={() => {
              trackClick(buttonTrackingIds.COOKIE_POLICY__ACCEPT_ALL)
              setCookieConsent(_cookieConsent(true))
            }}
          >
            Accept all
          </Button>
        </AnimateHeight>
        <p>Last updated: 2022-02-02</p>
        <p>
          This cookies policy is applicable to use of the application and not tied to
          the privacy policy of {firmLegalName || firmName}, we (Eligible.ai Ltd) are a
          third party supplier for this service and are a UK-based company.
        </p>
        <h3>About cookies</h3>
        <p>
          A cookie is a small text file that is downloaded onto your device when you
          access a website. It allows the website to recognise your device and store
          some information about your preferences or actions.
        </p>
        <h3>How we use cookies</h3>
        <p>
          We use cookies and similar technologies (<strong>cookies</strong>) on this
          website. This policy applies to our customer-application, and not to our
          website, eligible.ai, which has its own cookie policy.
        </p>
        <p>
          We will obtain your consent to use these cookies except when the cookie is
          strictly necessary for the operation of our website.
        </p>
        <p>Information collected by the cookies we use may include:</p>
        <ul className={s.dashedUl}>
          <li>
            information about your activity on our website, for example, information on
            items you have clicked on/searched for;
          </li>
          <li>
            device and browser settings and metrics, for example, event errors and time
            zone settings;
          </li>
          <li>content use history; and</li>
          <li>
            Uniform Resource Locators (URLs) used to gain access to, through and from
            our website (and relevant information regarding your visits).
          </li>
        </ul>
        <p>No cookies are set on our website by third-party service providers.</p>
        <p>
          We use a combination of session and persistent cookies. Session cookies keep
          track of your current visit and how you navigate the website and persistent
          cookies enable our website to recognise you as a repeat visitor when you
          return. The session cookies will be deleted from your device when you close
          your browser. Persistent cookies remain on your device after you have left the
          website.
        </p>
        <p>
          In addition to being able to refuse non-essential cookies through the
          functionality on our website, you can also manage cookies through your browser
          settings. Most browsers have cookies enabled by default, but you are able to
          change your cookie settings, which are usually found in the 'options' or
          'preferences' menu of your internet browser. You can block any cookies from
          any website by activating the setting on your browser that allows you to
          refuse the setting of some or all cookies. However, if you block all cookies
          you may not be able to access all or part of our website or use some features
          of our website. You can also use your browser settings to delete cookies. For
          more information about cookies please visit{" "}
          <a href="https://ico.org.uk/your-data-matters/online/cookies/">
            https://ico.org.uk/your-data-matters/online/cookies/
          </a>
          .
        </p>
        <p>For more detailed information on the cookies we use, please see below:</p>
        <p>
          The cookies used on our website are either set by us and fall into the
          following categories:
        </p>
        <div className={s.toggleRow}>
          <h3>Essential</h3>
          <Toggle isOn disabled secondaryColor />
        </div>
        <p>
          Those that are essential for the operation of the website, including cookies
          which allow you to interact with our website, recall selections you make as
          you move between pages, or which enable core functionality such as security,
          credentials authentication, network management and accessibility and to
          identify changes in information you provide to the website.
        </p>
        <table className={cx(s.table, s.border)}>
          <tbody>
            <tr>
              <td>
                <code>currentUserId</code>
              </td>
              <td>
                First party session-based cookie that helps us identify your account
                with us.
              </td>
            </tr>
            <tr>
              <td>
                <code>identToken</code>
              </td>
              <td>
                First party session-based cookie that helps us identify your account
                with us when you have come from one of our emails.
              </td>
            </tr>
            <tr>
              <td>
                <code>token</code>
              </td>
              <td>
                First party session-based cookie that helps us to know that you are
                logged in to our system.
              </td>
            </tr>
            <tr>
              <td>
                <code>callRequestedAt</code>
              </td>
              <td>
                First party persistent cookie that tracks the time when you last
                arranged a call so that we do not constantly ask you to.
              </td>
            </tr>
            <tr>
              <td>
                <code>cookieConsentKey</code>
              </td>
              <td>
                First party session-based cookie that tracks which cookies you have
                consented to.
              </td>
            </tr>
            <tr>
              <td>
                <code>mortgage</code>
              </td>
              <td>
                First party session-based cookie that helps us identify the mortgage
                you're looking at information about.
              </td>
            </tr>
            <tr>
              <td>
                <code>email</code>
              </td>
              <td>
                First party session-based cookie that helps us refer to your email when
                not logged in but wishing to unsubscribe.
              </td>
            </tr>
            <tr>
              <td>
                <code>directCallRequest</code>
              </td>
              <td>
                First party session-based cookie that helps us know when you've come
                from a link in an email that causes a "call request" to happen when you
                land on the site.
              </td>
            </tr>
          </tbody>
        </table>
        <div className={s.toggleRow}>
          <h3>Functional</h3>
          <Toggle
            secondaryColor
            isOn={functional}
            onToggle={() => {
              trackClick(buttonTrackingIds.COOKIE_POLICY__TOGGLE_FUNCTIONAL)
              setCookieConsent(_cookieConsent(!functional))
            }}
          />
        </div>
        <p>
          Those that are used to provide services or to remember choices you make so we
          can personalise our content for you. We use this information to customise your
          experience of our website to meet your preferences – for example, to allow you
          access to recently viewed pages, to allow you to dismiss notices and
          suggestions etc.
        </p>
        <table className={s.table}>
          <tbody>
            <tr>
              <td>
                <code>currentSituationBeenExpanded</code>
              </td>
              <td>
                First party persistent cookie that allows us to remember if you have
                asked for follow-on payments to be calculated.
              </td>
            </tr>
            <tr>
              <td>
                <code>blogFeedback</code>
              </td>
              <td>
                First party persistent cookie that allows us to remember what rating you
                gave a blog article.
              </td>
            </tr>
            <tr>
              <td>
                <code>portalArticles</code>
              </td>
              <td>
                First party persistent cookie that allows us to remember which articles
                you've read.
              </td>
            </tr>
          </tbody>
        </table>
      </ContentBox>
      <ContentBox removeMargin className={s.staticSaveButton}>
        <Button
          fullWidth
          trackingId={buttonTrackingIds.COOKIE_POLICY__SAVE}
          onClick={() => {
            history.goBack()
          }}
        >
          Save
        </Button>
      </ContentBox>
    </>
  )
}

export default CookiePolicyText
