import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { ExternalAdvicePageComponent } from "../../../components/consumer/LenderExternalAdvicePage/LenderExternalAdvicePage"
import { GetHelpComponent } from "../../../components/consumer/LenderGetHelpPage/LenderGetHelpPage"
import { InternalAdvicePageComponent } from "../../../components/consumer/LenderInternalAdvicePage/LenderInternalAdvicePage"
import { WithSwitchSupportComponent } from "../../../components/consumer/LenderWithSwitchSupportPage/LenderWithSwitchSupportPage"
import isUrl from "../../../lib/isUrl"
import axiosBaseQuery from "../axiosBaseQuery"
import { MORTGAGES_TAG_TYPE, mortgagesApi } from "../mortgages"
import {
  ExpiryPageComponent,
  OptionsPageComponent,
  PageConfig,
  AdviceResponse,
  WithoutAdviceResponse,
  SwitchPageComponent,
  AcceptanceModalResponse,
} from "./lenderApi.types"

const EXPIRY_DATE_PROPERTY = "/expiry-date/property"

// Tags
export const PAGE = "PAGE"

/**
 * This collection of API endpoints are based around lender firms,
 * At time of writing, their consumers are based around expiry-date
 * or current situation data and the URL schemes reflect that.
 *
 */
export const lenderApi = createApi({
  reducerPath: "lenderApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [PAGE],
  // 401s are ignored as they are expected when a users auth expires through inaction
  endpoints: (builder) => ({
    getOptionsPageComponents: builder.query<
      PageConfig<OptionsPageComponent>,
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/options/`,
        reportContext: {
          ignoreStatuses: [401, 403],
        },
      }),
      providesTags: [PAGE],
    }),
    getExpiryPageComponents: builder.query<
      PageConfig<ExpiryPageComponent>,
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/current-situation/`,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      providesTags: [PAGE],
    }),
    postWithoutAdvice: builder.mutation<
      WithoutAdviceResponse,
      { propertyId: string; direct?: boolean }
    >({
      query: ({ propertyId, direct }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/without-advice/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
        requestData: {
          direct,
        },
        sendIdent: true,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mortgagesApi.util.invalidateTags([MORTGAGES_TAG_TYPE]))
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    postResume: builder.mutation<
      AdviceResponse<
        SwitchPageComponent | InternalAdvicePageComponent | ExternalAdvicePageComponent
      >,
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/resume/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mortgagesApi.util.invalidateTags([MORTGAGES_TAG_TYPE]))
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    postInternalAdvice: builder.mutation<
      AdviceResponse<InternalAdvicePageComponent>,
      { propertyId: string; direct?: boolean }
    >({
      query: ({ propertyId, direct }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/internal-advice/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
        requestData: {
          direct,
        },
        sendIdent: true,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mortgagesApi.util.invalidateTags([MORTGAGES_TAG_TYPE]))
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    postExternalAdvice: builder.mutation<
      AdviceResponse<ExternalAdvicePageComponent>,
      { propertyId: string; direct?: boolean }
    >({
      query: ({ propertyId, direct }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/external-advice/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
        requestData: {
          direct,
        },
        sendIdent: true,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mortgagesApi.util.invalidateTags([MORTGAGES_TAG_TYPE]))
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    postWithSwitchSupport: builder.mutation<
      AdviceResponse<WithSwitchSupportComponent>,
      { propertyId: string; direct?: boolean }
    >({
      query: ({ propertyId, direct }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/with-switch-support/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
        requestData: {
          direct,
        },
        sendIdent: true,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(mortgagesApi.util.invalidateTags([MORTGAGES_TAG_TYPE]))
        } catch (e) {
          // do nothing
        }
      },
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    postGetHelp: builder.mutation<
      AdviceResponse<GetHelpComponent>,
      { propertyId: string }
    >({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/get-help/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
        sendIdent: true,
      }),
      invalidatesTags: (result) =>
        getInvalidationTagsFromImmediateRedirect(result?.immediateRedirect),
    }),
    remindWhenProductsAvailable: builder.mutation<
      void,
      { propertyId: string; userId?: string | null; token?: string | null }
    >({
      query: ({ propertyId, userId, token }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/remind-when-products-available/`,
        method: "POST",
        requestData: { id: userId, token },
        reportContext: {
          ignoreStatuses: [401, 409],
        },
      }),
      invalidatesTags: [PAGE],
    }),
    viewPaymentsEvent: builder.mutation<{}, { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/payments-viewed/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),
    viewWhyHappenedEvent: builder.mutation<{}, { mortgageId: string }>({
      query: ({ mortgageId }) => ({
        url: `/mortgage/${mortgageId}/why-is-this-happening-viewed/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),
    getAcceptanceModal: builder.query<AcceptanceModalResponse, { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: `${EXPIRY_DATE_PROPERTY}/${propertyId}/acceptance-modals/`,
        reportContext: {
          ignoreStatuses: [401],
        },
        sendIdent: true,
      }),
    }),
  }),
})

function getInvalidationTagsFromImmediateRedirect(
  immediateRedirect?: string | null
): TagDescription<"PAGE">[] {
  if (immediateRedirect && isUrl(immediateRedirect)) {
    return []
  }
  return [PAGE]
}

export const {
  useGetOptionsPageComponentsQuery,
  useGetExpiryPageComponentsQuery,
  usePostWithoutAdviceMutation,
  usePostResumeMutation,
  usePostInternalAdviceMutation,
  usePostExternalAdviceMutation,
  usePostWithSwitchSupportMutation,
  usePostGetHelpMutation,
  useRemindWhenProductsAvailableMutation,
  useViewPaymentsEventMutation,
  useViewWhyHappenedEventMutation,
  useGetAcceptanceModalQuery,
} = lenderApi
