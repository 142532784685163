import Modal from "../consumer/Lifecycle/Modal"
import s from "./SplashModal.module.scss"

type SplashModalProps = {
  children: React.ReactNode
  onRequestClose?: () => void
  isOpen: boolean
}

function SplashModal({ children, isOpen, onRequestClose }: SplashModalProps) {
  return (
    <Modal
      open={isOpen}
      preventEscClose
      handleClose={() => {
        onRequestClose?.()
      }}
    >
      <div className={s.overlay}>
        <div className={s.base}>{children}</div>
      </div>
    </Modal>
  )
}

export default SplashModal
