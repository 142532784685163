import cx from "classnames"

import s from "./BurgerButton.module.scss"
import Button, { ButtonFill, ButtonStyle } from "./Button"

type Props = {
  onClick: () => void
  className: string
  cross: boolean
  isBusinessSide?: boolean
  hide?: boolean
}

function BurgerButton(props: Props) {
  const { onClick, className, cross, isBusinessSide = false, hide } = props

  return (
    <Button
      onClick={onClick}
      className={cx(s.burgerButton, className, {
        [s.isActive]: cross,
        [s.isBusinessSide]: isBusinessSide,
      })}
      style={ButtonStyle.NONE}
      fill={ButtonFill.NONE}
    >
      <div className={cx(s.burgerBox, { [s.hidden]: hide })}>
        <div className={s.burgerInner} />
      </div>
    </Button>
  )
}

export default BurgerButton
