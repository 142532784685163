import cx from "classnames"
import { useState } from "react"

import { ReactComponent as MenuCross } from "../../images/MenuCross.svg"
import s from "./MobileUserMenu.module.scss"
import StyledAddress from "./StyledAddress"

type AddressContent = {
  address: string
  mortgageId: string
  onClick: () => void
}

type TitleContent = {
  title: string
  onClick: () => void
}

type MenuContent = AddressContent | TitleContent

const isTitleContent = (item: MenuContent): item is TitleContent => {
  return Object.prototype.hasOwnProperty.call(item, "title")
}

type MobileMenuProps = {
  content: MenuContent[]
  currentMortgage?: string
  currentUsersName?: string
  menuButton: React.ReactNode
}

type ActionProps = {
  children: React.ReactNode
  isActive?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

function Action({ children, isActive = false, onClick }: ActionProps) {
  return (
    <button
      type="button"
      className={isActive ? s.activeAction : s.action}
      onClick={onClick}
    >
      <span className={s.wrapper}>{children}</span>
    </button>
  )
}

function MobileUserMenu({
  content,
  currentMortgage,
  currentUsersName,
  menuButton,
}: MobileMenuProps) {
  const [open, setOpen] = useState(false)

  const handleClose =
    (onClose?: () => void) => (event: React.MouseEvent<HTMLElement>) => {
      onClose?.()
      if (event) {
        event.stopPropagation()
      }
      setOpen(false)
    }

  if (open) {
    return (
      <div className={cx("tablet-and-smaller", "fade-in", s.mobileMenuOpen)}>
        <button type="button" className={s.modalClose} onClick={handleClose()}>
          <MenuCross />
        </button>
        <div className={s.menuSelection}>
          {currentUsersName}
          {content.map((item) =>
            isTitleContent(item) ? (
              <Action key={item.title} onClick={handleClose(item.onClick)}>
                {item.title}
              </Action>
            ) : (
              <Action
                key={item.mortgageId}
                isActive={currentMortgage === item.mortgageId}
                onClick={handleClose(item.onClick)}
              >
                <StyledAddress address={item.address} />
              </Action>
            )
          )}
        </div>
      </div>
    )
  }
  return (
    <div className={cx("tablet-and-smaller", s.mobileMenuClosed)}>
      <button
        type="button"
        className={s.modalOpen}
        onClick={() => {
          setOpen(true)
        }}
      >
        {menuButton}
      </button>
    </div>
  )
}

export default MobileUserMenu
