import qs from "query-string"
import { RouteComponentProps } from "react-router"

export const getCurrentPageNumber = (
  location: RouteComponentProps["location"]
): number => {
  const searchParams = qs.parse(location.search)
  const getPage = searchParams.page

  if (!getPage) {
    // Bad typing in the query-string library means undefined can be returned, not just null
    return 1
  }

  if (Array.isArray(getPage)) {
    const stringArray = getPage.filter((item): item is string => item !== null)
    return parseInt(stringArray[0], 10)
  }

  return parseInt(getPage, 10)
}

export const getCurrentOffset = (size: number, curPage: number): number =>
  size * (curPage - 1)

export const getPageCount = (total: number, size: number): number =>
  Math.ceil(total / size)
