import cx from "classnames"
import { ReactNode, useEffect } from "react"

import useTrackClick from "../../hooks/trackClick"
import Modal from "../consumer/Lifecycle/Modal"
import s from "./PopIn.module.scss"

export type PopInProps = {
  children?: ReactNode
  hide: () => void
  shown: boolean
  wide?: boolean
  modalClass?: string
  modalLabel?: string
  noFade?: boolean
}

const PopIn = ({
  children,
  hide,
  shown,
  wide = false,
  modalClass = undefined,
  modalLabel,
  noFade,
}: PopInProps) => {
  const { trackClick } = useTrackClick()

  const handleRequestClose = () => {
    if (modalLabel) {
      trackClick(`${modalLabel} - Modal close`)
    }
    hide()
  }

  useEffect(() => {
    if (shown && modalLabel) {
      trackClick(`${modalLabel} - Modal open`)
    }
    /* TODO: ENG-5731 Remove eslint-disable for dependency arrays in hooks */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shown, trackClick])

  return (
    <Modal
      handleClose={handleRequestClose}
      open={shown}
      modalClass={cx({ [s.wideContent]: wide }, s.modal, modalClass)}
      noFade={noFade}
    >
      {children}
    </Modal>
  )
}

export default PopIn
