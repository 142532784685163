import axios from "axios"
import { useCallback } from "react"

import { toastNotification } from "../components/common/ToastNotification"
import { AuthErrorReason } from "../constants/authErrorReasons"
import { errorMessages } from "../constants/retain"
import HttpStatusCode from "../lib/httpStatusCodes"

const { BAD_TOKEN, EXPIRED_TOKEN } = AuthErrorReason

/**
 * Handles toast notifications for errors.
 * Reporting to Sentry/logging should be handled elsewhere.
 */
export default function useSetError() {
  return useCallback((error?: unknown) => {
    if (
      axios.isAxiosError(error) &&
      error?.response?.status === HttpStatusCode.UNAUTHORIZED
    ) {
      if ([BAD_TOKEN, EXPIRED_TOKEN].includes(error.response?.data?.errorReason)) {
        toastNotification(errorMessages.EXPIRED_TOKEN, "error", {
          autoClose: false,
        })
      } else {
        toastNotification(
          "Sorry, there's been an authentication problem. Please log in.",
          "error",
          {
            autoClose: false,
          }
        )
      }
    } else {
      toastNotification(
        "Sorry, there's been a problem. We're looking into a fix.",
        "error",
        {
          autoClose: false,
        }
      )
    }
  }, [])
}
