import { Firm } from "../../types/retain/Firms.types"
import { sanitizer } from "./sanitizer"

export function parseFirm(firm: Firm): Firm {
  if (!firm) throw Error("Missing firm")

  return {
    ...firm,
    webDisclaimer: sanitizer(firm.webDisclaimer),
    didYouKnow: sanitizer(firm.didYouKnow),
  }
}
