import dayjs from "dayjs"

import { ProductOption } from "../../redux/services/productSelection/ProductSelection.types"
import { Mortgage, RawMortgage } from "../../types/retain/Mortgages.types"

export function parseRawMortgages(rawMortgages: RawMortgage[]): Mortgage[] {
  let nullProperties = 0
  return rawMortgages.map((rawMortgage) => {
    const parsedProducts: ProductOption[] =
      rawMortgage.selectedProductOptions?.map((product) => ({
        ...product,
        aprc: parseFloat(product.aprc),
        fees: parseFloat(product.fees),
        followOnRate: parseFloat(product.followOnRate),
        ltv: parseFloat(product.ltv),
        monthlyFollowOnPayment: parseFloat(product.monthlyFollowOnPayment),
        monthlyPreferentialPayment: parseFloat(product.monthlyPreferentialPayment),
        preferentialRate: parseFloat(product.preferentialRate),
        ercYear1: parseFloat(product.ercYear1),
        ercYear2: parseFloat(product.ercYear2),
        ercYear3: parseFloat(product.ercYear3),
        ercYear4: parseFloat(product.ercYear4),
        ercYear5: parseFloat(product.ercYear5),
      })) ?? []
    const address = rawMortgage.prop.address || `Property ${(nullProperties += 1)}`
    return {
      ...rawMortgage,
      prop: {
        ...rawMortgage.prop,
        address,
      },
      expired: dayjs(rawMortgage.expiryDate).isBefore(dayjs()),
      selectedProductOptions: parsedProducts,
    }
  })
}

export function createRecordByMortgageId(
  mortgages: Mortgage[]
): Record<string, Mortgage> {
  return mortgages.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {}
  )
}
