import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { PaginatedResponse } from "../../../types/retain"
import {
  ArticleType,
  BlogPost,
  BlogPostAction,
  BlogPostActionExtraContent,
} from "../../../types/retain/BlogPost.types"
import axiosBaseQuery from "../axiosBaseQuery"

export const blogPostsApi = createApi({
  reducerPath: "blogPostsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1/blog-posts",
  }),
  endpoints: (builder) => ({
    getBlogPosts: builder.query<
      PaginatedResponse<BlogPost>,
      {
        tags?: string[]
        type?: ArticleType
        userId?: string
        token?: string
      }
    >({
      query: ({ tags, type, token, userId }) => ({
        url: `/`,
        params: {
          userId,
          token,
          tags,
          type,
        },
      }),
    }),
    postBlogEvent: builder.mutation<
      BlogPost,
      {
        slug: string
        action: BlogPostAction
        type: ArticleType
        extraContent: BlogPostActionExtraContent
        token?: string
        userId?: string
      }
    >({
      query: ({ slug, action, type, extraContent, token, userId }) => ({
        url: `/${slug}/event/`,
        method: "POST",
        params: { userId, token, type },
        requestData: {
          action,
          ...extraContent,
        },
      }),
    }),
    viewBlogEvent: builder.mutation<{}, void>({
      query: () => ({
        url: `/viewed/`,
        method: "POST",
      }),
    }),
  }),
})

export const {
  useGetBlogPostsQuery,
  usePostBlogEventMutation,
  useViewBlogEventMutation,
} = blogPostsApi
