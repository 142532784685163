import dayjs from "dayjs"

import { useContent_Unstable } from "../../../hooks/blocks/useContent_Unstable"
import { useImageContent_Unstable } from "../../../hooks/blocks/useImageContent_Unstable"
import useMdxContent_Unstable from "../../../hooks/mdx/useMdxContent_Unstable"
import { percent } from "../../../lib/formatters"
import { ContentId } from "../../../types/retain/Content.types"
import { Mortgage } from "../../../types/retain/Mortgages.types"
import { MortgageOverviewPaths } from "./mortgageOverviewBlocks"

const titlePath = ContentId.MORTGAGE_OVERVIEW_POP_IN_TITLE
const imagePath = ContentId.MORTGAGE_OVERVIEW_WHY_HAPPENING_IMAGE
const mdxContentPath = ContentId.MORTGAGE_OVERVIEW_WHY_HAPPENING_MDX_CONTENT

function useMortgageOverviewContent(mortgage: Mortgage | null) {
  const { b, bRef } = useContent_Unstable<MortgageOverviewPaths>()

  const whyPaymentsChangePath = mortgage?.expired
    ? ContentId.WHY_PAYMENTS_CHANGE_POST_EXPIRY
    : ContentId.WHY_PAYMENTS_CHANGE_PRE_EXPIRY

  const whyPaymentsChangeString = b(whyPaymentsChangePath)
  const whyPaymentsChangeRef = bRef(whyPaymentsChangePath)

  const popInTitle = b(titlePath)
  const popInTitleRef = bRef(titlePath)

  const { image, imageRef } = useImageContent_Unstable<MortgageOverviewPaths>()
  const whyHappeningImage = image(imagePath)
  const whyHappeningImageRef = imageRef(imagePath)

  const whyHappeningMdxContent = useMdxContent_Unstable(mdxContentPath, {
    startDate: mortgage ? dayjs(mortgage.startDate).format("MMMM DD YYYY") : null,
    preferentialRate: mortgage ? `${mortgage.preferentialRate.toString()}%` : null,
    followOnRate: mortgage ? percent(mortgage.followOnRate / 100) : null,
    expiryDate: mortgage ? dayjs(mortgage.expiryDate).format("MMMM DD YYYY") : null,
    initialTermInYears: mortgage
      ? Math.round(mortgage.monthsOfPreferentialRate / 12)
      : null,
  })

  return {
    whyPaymentsChangeString,
    whyPaymentsChangeRef,
    whyHappeningImage,
    whyHappeningImageRef,
    whyHappeningMdxContent,
    popInTitle,
    popInTitleRef,
  }
}

export default useMortgageOverviewContent
