import { LabelPaths } from "../../../hooks/blocks/util.types"

const withoutAdviceDescriptionDefault = `Switching to a new deal may avoid a higher payment.

View new rates and deals available to you. Apply and switch today.`

const pausedDescriptionDefault = `Alerts are now paused, you will still be alerted as soon as new rates are available.

In the meantime, you can explore the process to better understand the switching procedure.`

const actionedDescriptionDefault = `Thank you for selecting an option for proceeding to apply for a new rate.

Click below if you need to get in touch about your mortgage.`

const switchingDescriptionDefault = `Thanks for actioning your mortgage, we will process your application shortly.

In the meantime, you can explore the process to better understand the switching procedure.`

const incompleteSelectionDescriptionDefault = `Alerts are paused while you action your move.

You haven’t completed your product review, click below to see and select products.`

const externalIncompleteCtaDescriptionDefault = `Alerts are paused while you action your switch.

If you haven’t completed your product switch, click below to see deals.`

const internalDescriptionDefault = `Switching to a new rate may avoid a higher payment.

Our mortgage advisers can discuss our products with you, recommend a deal to suit your needs and help you switch.

Remember: you will need to speak to one of our mortgage advisers if you’re looking to borrow more.

Select ‘Get help’ below to set-up a time.`

export const currentSituationPageBlocks = {
  /* TODO: ENG-5860 Convert scheduleCall into 2 content blocks */
  scheduleCall: {
    type: "text",
    description: `The CTA button label for scheduling a call on the Current Situation page or Lifecycle pages - note the default will be "Book call" on Lifecycle CTA pages and "Call me back" on Lender Current Situation`,
    default: null, // default will be "Book call" (Lifecycle) or "Call me back" (Lender)
  },
  currentSituationAllGood: {
    type: "text",
    description:
      "The CTA button label for Ignoring Alerts on a particular mortgage on the Current Situation page",
    default: "Ignore Alerts",
  },
  currentSituationSecondaryCta: {
    content: {
      type: "text",
      description:
        "The secondary CTA button label on the Current Situation page, it will only display if this and currentSituationSecondaryCtaUrl are both set",
      default: null,
    },
    image: {
      type: "image",
      description:
        "The icon that displays inside the secondary CTA button on the Current Situation page",
      default: null,
    },
  },
  currentSituationSecondaryCtaUrl: {
    type: "text",
    description:
      "The URL that the secondary CTA button Current Situation page will navigate the user to, it will only work if this and currentSituationSecondaryCta are both set",
    default: null,
  },
  currentSituationTitle: {
    type: "text",
    description: "The title that goes at the top of the Current Situation page",
    default: "Your current situation",
  },
  currentSituationSecondaryZeroMortgages: {
    content: {
      type: "text",
      description:
        "The CTA button that displays on the Current Situation page if user has no mortgages to action, it will only display if this and currentSituationSecondaryZeroMortgagesUrl are both set",
      default: null,
    },
    image: {
      type: "image",
      description:
        "The icon that displays inside the CTA button on the Current Situation page if user has no mortgages to action",
      default: null,
    },
  },
  currentSituationSecondaryZeroMortgagesUrl: {
    type: "text",
    description:
      "The URL that the CTA button on the Current Situation page will navigate the user to if they have no mortgages to action, it will only work if this and currentSituationSecondaryZeroMortgages are both set",
    default: null,
  },
  currentSituation: {
    withoutAdviceContentBox: {
      headerTitle: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for without-advice, missing by default",
        default: null,
      },
      descriptionContent: {
        type: "markdown",
        description: "Content that goes in the Content Box for without-advice",
        default: withoutAdviceDescriptionDefault,
      },
    },
    pausedContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for paused alerts, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description: "Content that goes in the Content Box for paused alerts",
        default: pausedDescriptionDefault,
      },
    },
    actionedContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for actioned mortgages, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description: "Content that goes in the Content Box for actioned mortgages",
        default: actionedDescriptionDefault,
      },
    },
    lenderPreamble: {
      expiredMortgage: {
        mdxContent: {
          type: "mdx",
          description:
            "The preamble text for an expired mortgage, beneath the 'Your current situation' heading near the top of the page",
          default: `The initial term on {props.pronounLowercase} mortgage ended {props.expiryDate}. {props.pronounCapitalized} monthly payment changed when this happened.`,
        },
      },
      activeMortgage: {
        mdxContent: {
          type: "mdx",
          description:
            "The preamble text for an active mortgage, beneath the 'Your current situation' heading near the top of the page",
          default: `The initial term on {props.pronounLowercase} mortgage ends {props.expiryDate}. {props.pronounCapitalized} monthly payment will change when this happens.`,
        },
      },
    },
    switchingContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for switching mortgages, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description: "Content that goes in the Content Box for switching mortgages",
        default: switchingDescriptionDefault,
      },
    },
    notInterestedContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for not interested mortgages, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description:
          "Content that goes in the Content Box for not interested mortgages",
        default: "Alerts about moving to a new deal are stopped.",
      },
    },
    incompleteContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for mortgages with incomplete product selections, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description:
          "Content that goes in the Content Box for mortgages with incomplete product selections",
        default: incompleteSelectionDescriptionDefault,
      },
    },
    externalIncompleteContentBox: {
      title: {
        type: "text",
        description:
          "Heading that goes at the top of the Content Box for mortgages with incomplete external CTAs, missing by default",
        default: null,
      },
      content: {
        type: "markdown",
        description:
          "Content that goes in the Content Box for mortgages with incomplete external CTAs",
        default: externalIncompleteCtaDescriptionDefault,
      },
    },
  },
  internalAdviceButton: {
    title: {
      type: "text",
      description: "Optional title for the internal advice content box",
      default: null,
    },
    description: {
      type: "markdown",
      description: "Description for the button in the internal advice content box",
      default: internalDescriptionDefault,
    },
  },
  withSwitchSupportButton: {
    title: {
      type: "text",
      description: "Optional title for the with switch support content box",
      default: null,
    },
    description: {
      type: "markdown",
      description: "Description for the button in the with switch support content box",
      default: null,
    },
  },
  setReminderBox: {
    title: {
      type: "text",
      description: "Optional title for the reminder content box",
      default: null,
    },
    content: {
      type: "markdown",
      description: "Description for the button in the reminder content box",
      default: null, // fallback is set on the usage of this block
    },
  },
  help: {
    title: {
      type: "text",
      description: "Optional title for More options content box",
      default: null,
    },
    content: {
      type: "markdown",
      description: "Description for the button in the More options content box",
      default: null, // fallback is set on the usage of this block
    },
  },
} as const

export type CurrentSituationPagePaths = LabelPaths<typeof currentSituationPageBlocks>
