import { RunnerOptions } from "@mdx-js/mdx/lib/util/resolve-evaluate-options"
import * as Sentry from "@sentry/react"
import { MDXModule } from "mdx/types"
import { useEffect, useState } from "react"
import { unstable_batchedUpdates } from "react-dom"
import { Link } from "react-router-dom"
import * as runtime from "react/jsx-runtime"

import retry from "../../lib/retry"
import { useContent_Unstable } from "../blocks/useContent_Unstable"
import Accordion from "./defaults/Accordion"
import AccountNumberInfoBox from "./defaults/AccountNumberInfoBox"
import LinkButton from "./defaults/LinkButton"
import OpenInfoBoxButton from "./defaults/OpenInfoBoxButton"
import WrappedInfoBox from "./defaults/WrappedInfoBox"

function useMdxContent_Unstable<Paths extends string = string>(
  path: Paths,
  passedProps: Record<string, string | boolean | number | null> = {},
  options?: { fallback: string }
) {
  const [output, setOutput] = useState<MDXModule>()
  const [components, setComponents] = useState({})
  const [renderError, setRenderError] = useState(false)

  const { b } = useContent_Unstable<Paths>({ type: "mdx" })
  const source = b(path, { fallback: options?.fallback })

  useEffect(() => {
    let ignore = false
    if (source) {
      const runnerOptions = { ...runtime } as RunnerOptions
      const otherOptions = {
        outputFormat: "function-body",
      }
      const evaluateSourceAndImportComponents = async () => {
        try {
          const mdx = await retry(() => import("@mdx-js/mdx"))
          const defaultComponents = [
            LinkButton,
            WrappedInfoBox,
            Accordion,
            Link,
            OpenInfoBoxButton,
            AccountNumberInfoBox,
          ]
          const mdxDefaultNames = [
            "LinkButton",
            "WrappedInfoBox",
            "Accordion",
            "Link",
            "OpenInfoBoxButton",
            "AccountNumberInfoBox",
          ]
          const defaultMap = defaultComponents.reduce(
            (mapped, comp: Function, currentIndex) => {
              return { ...mapped, [mdxDefaultNames[currentIndex]]: comp }
            },
            {}
          )
          const result = await mdx.evaluate(source, {
            ...runnerOptions,
            ...otherOptions,
          })
          if (!ignore) {
            unstable_batchedUpdates(() => {
              setComponents({ ...defaultMap })
              setOutput(result)
            })
          }
        } catch (error) {
          if (error instanceof Error) {
            Sentry.captureMessage(`MDX rendering error: ${error.message}`, "error")
          }
          setRenderError(true)
        }
      }
      evaluateSourceAndImportComponents()
    }
    return () => {
      ignore = true
    }
  }, [source])

  if (renderError) {
    return (
      <span>
        Apologies, the text we wanted to display here could not be loaded. Please try
        refreshing.
      </span>
    )
  }

  return output?.default({ components, ...passedProps }) ?? undefined
}

export default useMdxContent_Unstable
