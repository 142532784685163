import { useClickOutside } from "@mantine/hooks"
import cx from "classnames"
import { ReactElement, useState } from "react"

import { ReactComponent as Chevron } from "../../../images/Chevron.svg"
import { ClickableDiv } from "../index"
import s from "./Dropdown.module.scss"

interface ClassNameAdditions {
  dropdown: string
  dropdownInnerClosed: string
  dropdownInnerOpen: string
  dropdownContent: string
}

interface DropdownProps {
  header: ReactElement
  children: (params: { closeDropdown: EventHandler }) => ReactElement | ReactElement[]
  dark?: boolean
  describedBy?: string
  labelledBy?: string
  classNameAdditions?: Partial<ClassNameAdditions>
  id?: string
  disabled?: boolean
}

type EventHandler = (e?: React.MouseEvent) => void

function Dropdown({
  header,
  children,
  dark = false,
  disabled = false,
  describedBy,
  labelledBy,
  classNameAdditions,
  id,
}: DropdownProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const stopPropagationThen =
    (cb: () => void): EventHandler =>
    (e): void => {
      if (e) e.stopPropagation()
      cb()
    }

  const open = () => !disabled && setIsExpanded(true)
  const close = () => !disabled && setIsExpanded(false)
  const toggle = () => !disabled && setIsExpanded(!isExpanded)

  const ref = useClickOutside(() => close())

  return (
    <div
      ref={ref}
      id={id}
      className={cx(s.dropdownContainer, classNameAdditions?.dropdown, {
        [s.disabled]: disabled,
      })}
      role="listbox"
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
      tabIndex={0}
      onFocus={() => open()}
      onBlur={() => stopPropagationThen(() => close())}
    >
      <div
        className={cx(
          s.dropdown,
          { [s.dark]: dark },
          classNameAdditions?.dropdownInnerClosed
        )}
      >
        <ClickableDiv className={s.header} onClick={() => toggle()}>
          <div className={s.headerContent}>{header}</div>
          <Chevron className={s.chevron} />
        </ClickableDiv>
      </div>
      {isExpanded && (
        <div
          className={cx(
            s.dropdown,
            s.open,
            { [s.dark]: dark },
            classNameAdditions?.dropdownInnerOpen
          )}
        >
          <ClickableDiv className={s.header} onClick={() => toggle()}>
            <div className={s.headerContents}>{header}</div>
            <Chevron className={s.chevron} />
          </ClickableDiv>
          <ClickableDiv
            className={cx(s.content, classNameAdditions?.dropdownContent)}
            onClick={() => stopPropagationThen(() => close())}
          >
            <div className={s.list}>
              {children({
                closeDropdown: stopPropagationThen(() => close()),
              })}
            </div>
          </ClickableDiv>
        </div>
      )}
    </div>
  )
}

export default Dropdown
