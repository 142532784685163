/**
 * adapted from https://stackoverflow.com/a/164994
 *
 *  - uses the ignore case flag instead of embedding it in the regex
 *  - makes use of capture and non-capture groups such that the outward and
 *    inward parts of the postcode can be referenced separately in the replace
 *    phase
 *  - removed the GIRO bank reference. It is defunct and was never property
 */

const OUTWARD_CODE =
  "([A-Z][0-9]{1,2}|(?:[A-Z][A-HJ-Y][0-9]{1,2}|(?:[A-Z][0-9][A-Z]|[A-Z][A-HJ-Y][0-9][A-Z]?)))"
const INWARD_CODE = "([0-9][A-Z]{2})"

const postcodeRegex = new RegExp(`${OUTWARD_CODE}\\s?${INWARD_CODE}$`, "i")

export default postcodeRegex
