import ellipsisAt from "../../lib/ellipsisAt"
import OneLineSummary from "./OneLineSummary"

type Props = {
  address: string
}

function StyledAddress({ address }: Props) {
  return <OneLineSummary ellipsisAt={ellipsisAt}>{address}</OneLineSummary>
}

export default StyledAddress
