import { cloneDeep } from "lodash-es"

import { Lead, RawLead } from "../../types/retain/Consumer.types"
import { parseRawMortgages } from "./parseMortgages"

export function parseLead(consumer: RawLead): Lead {
  if (!consumer) throw Error("Missing consumer (lead)")

  const c = cloneDeep<Lead>({ ...consumer, mortgages: [] })

  if (consumer.mortgages) {
    c.numMortgages = consumer.mortgages.length
    c.mortgages = parseRawMortgages(consumer.mortgages)
  }

  if (consumer.meetingBookedFrom) {
    c.meetingBookedFrom = new Date(consumer.meetingBookedFrom)
  }

  if (consumer.meetingBookedUntil) {
    c.meetingBookedUntil = new Date(consumer.meetingBookedUntil)
  }

  return c
}
