import s from "./RippleAnimation.module.scss"

type RippleAnimationProps = {
  children: React.ReactNode
}

function RippleAnimation({ children }: RippleAnimationProps) {
  return (
    <div className={s.animationContainer}>
      <div className={s.firstRing} />
      <div className={s.secondRing} />
      <div className={s.thirdRing} />
      <div className={s.fourthRing} />
      <div className={s.pulseContainer}>{children}</div>
    </div>
  )
}

export default RippleAnimation
