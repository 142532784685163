import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "../axiosBaseQuery"
import {
  ProductSelectionMutation,
  ProductSelectionOptions,
  ProductSelectionResponse,
  ProductSelectionSubmission,
} from "./ProductSelection.types"

export const productSelectionApi = createApi({
  reducerPath: "productSelectionApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1/product-selection",
  }),
  endpoints: (builder) => ({
    getProductSelection: builder.query<ProductSelectionOptions, { propertyId: string }>(
      {
        query: ({ propertyId }) => ({
          url: `/${propertyId}/`,
        }),
        transformResponse: (response: ProductSelectionResponse) => ({
          ...response,
          mortgages: response.mortgages.map(
            ({
              id,
              products,
              monthlyFollowOnPayment,
              monthlyPreferentialPayment,
              followOnRate,
              expiryDate,
              givenCurrentLoanToValue,
              givenCurrentBalance,
            }) => ({
              id,
              expiryDate,
              followOnRate: parseFloat(followOnRate),
              monthlyFollowOnPayment: parseFloat(monthlyFollowOnPayment),
              monthlyPreferentialPayment: parseFloat(monthlyPreferentialPayment),
              givenCurrentLoanToValue: parseFloat(givenCurrentLoanToValue || "0"),
              givenCurrentBalance: parseFloat(givenCurrentBalance || "0"),
              products: products.map((product) => ({
                ...product,
                monthlyPreferentialPayment: parseFloat(
                  product.monthlyPreferentialPayment
                ),
                preferentialRate: parseFloat(product.preferentialRate),
                fees: parseFloat(product.fees),
                aprc: parseFloat(product.aprc),
                monthlyFollowOnPayment: parseFloat(product.monthlyFollowOnPayment),
                followOnRate: parseFloat(product.followOnRate),
                ltv: parseFloat(product.ltv),
                ercYear1: parseFloat(product.ercYear1),
                ercYear2: parseFloat(product.ercYear2),
                ercYear3: parseFloat(product.ercYear3),
                ercYear4: parseFloat(product.ercYear4),
                ercYear5: parseFloat(product.ercYear5),
              })),
            })
          ),
        }),
      }
    ),

    postProductsTermsAccepted: builder.mutation<
      void,
      {
        propertyId: string
      }
    >({
      query: ({ propertyId }) => ({
        url: `/${propertyId}/products-terms-accepted/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),

    postProductsSelected: builder.mutation<
      void,
      {
        propertyId: string
        mortgages: {
          id: string
          products: {
            id: string
          }[]
        }[]
      }
    >({
      query: ({ propertyId, mortgages }) => ({
        url: `/${propertyId}/products-selected/`,
        method: "POST",
        requestData: { mortgages },
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),

    postProductsConfirmed: builder.mutation<
      ProductSelectionMutation,
      {
        propertyId: string
        productConfirmationSubmission: ProductSelectionSubmission
      }
    >({
      query: ({ propertyId, productConfirmationSubmission }) => ({
        url: `/${propertyId}/products-confirmed/`,
        method: "POST",
        requestData: productConfirmationSubmission,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
    }),
  }),
})

export const {
  useGetProductSelectionQuery,
  usePostProductsTermsAcceptedMutation,
  usePostProductsSelectedMutation,
  usePostProductsConfirmedMutation,
} = productSelectionApi
