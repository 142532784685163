import axios from "axios"
import { Dispatch } from "redux"

import { progressLoader } from "../../components/common/tricklingProgress"
import ActionType from "../../constants/actionTypes"
import endpoints from "../../constants/endpoints"
import { isConsumer } from "../../lib/isConsumer"
import { parseFirm } from "../../lib/parsers/parseFirm"
import reportAxiosError from "../../lib/reportAxiosError"
import { Consumer } from "../../types/retain/Consumer.types"
import { Firm } from "../../types/retain/Firms.types"
import { updateSession } from "../slices/persistantSession"
import { RootState } from "../store"
import buildTagsFrom403 from "./buildTagsFrom403"

export const getUser = () => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    dispatch({ type: ActionType.USER_REQUEST })
    const userId = getState().persistant.session.userId
    const token = getState().persistant.session.identToken
    const params =
      token && userId
        ? {
            token,
            user_id: userId,
          }
        : undefined
    try {
      const response = await axios.get(endpoints.user, {
        params,
      })
      const {
        firm,
        currentUser,
        canRequestCall,
        businessSide,
        firstTimeUser,
        home,
        permissions,
        lists,
        featureFlags,
        onboarding,
      } = response.data

      if (!businessSide) {
        progressLoader.setOptions({
          color: firm?.primaryColor ?? "#0485ff",
        })
      }

      const isAuth = !!currentUser?.id
      dispatch(updateSession({ userId: currentUser?.id }))
      dispatch({
        type: ActionType.USER_SUCCESS,
        isAuth,
        authExpiry: response.headers["x-auth-expiry"],
        firstTimeUser: !!firstTimeUser,
        businessSide,
        firm: firm && parseFirm(firm),
        currentUser,
        currentUserId: currentUser?.id,
        canRequestCall,
        home,
        permissions,
        availableLists: lists,
        featureFlags,
        onboarding,
      })
    } catch (e) {
      reportAxiosError(e, {
        buildTagsFromError: buildTagsFrom403,
      })
      dispatch({
        type: ActionType.USER_FAILURE,
        errorMessage: "Error fetching user",
      })
    }
  }
}

export const updateUser = (firm: Firm, currentUser: Consumer, token: string) => {
  return (dispatch: Dispatch): void => {
    dispatch(updateSession({ authToken: token, userId: currentUser?.id }))
    const isAuth = !!currentUser?.id
    dispatch({
      type: ActionType.USER_SUCCESS,
      isAuth,
      businessSide: !currentUser.isConsumer,
      firm: firm && parseFirm(firm),
      currentUser,
      currentUserId: currentUser?.id,
      canRequestCall: isConsumer(currentUser) && currentUser?.canRequestCall,
    })
  }
}
