import { lifecycleModule } from "../../components/consumer/Lifecycle/utils/lifecycleModules"
import { SwitchStartDatePreference } from "../../components/consumer/ProductSelection/ProductSelectionSummaryPage/SwitchStartDatePreference.types"
import {
  ProductOption,
  ProductOptionWithStrings,
} from "../../redux/services/productSelection/ProductSelection.types"
import { notInterestedCategories } from "../retain"
import { BareUser } from "./Consumer.types"

export const paymentTypes = {
  INTEREST_ONLY: "interest-only",
  REPAYMENT: "repayment",
  PART: "part-and-part",
  SPLIT: "split",
} as const

export type paymentTypes = typeof paymentTypes[keyof typeof paymentTypes]

export const paymentSchemes = {
  FIXED: "fixed",
  DISCOUNT: "discount",
  TRACKER: "tracker",
  NONE: "",
} as const

export type paymentSchemes = typeof paymentSchemes[keyof typeof paymentSchemes]

export const loanPurpose = {
  FURTHER_ADVANCE: "further-advance",
  ADDITIONAL_BORROWING: "additional-borrowing",
  PURCHASE: "purchase",
  REMORTGAGE: "remortgage",
  BRIDGING: "bridging",
  PORTING: "porting",
  PRODUCT_TRANSFER: "product-transfer",
  FIRST_TIME_BUYER: "first-time-buyer",
} as const

export type loanPurpose = typeof loanPurpose[keyof typeof loanPurpose]

export const mortgageTypes = {
  BUY_TO_LET: "buy-to-let",
  OWNER_OCCUPIED: "owner-occupied",
} as const

export type mortgageTypes = typeof mortgageTypes[keyof typeof mortgageTypes]

export const mortgageState = {
  NOT_INTERESTED: "not-interested",
  UNKNOWN: "unknown",
  MISSED: "missed",
  LOST: "lost",
  SUBMITTED: "submitted",
} as const

export type mortgageState = typeof mortgageState[keyof typeof mortgageState]

type PropertyType = {
  id: string
  address: string
  value: number
  crmLink?: string | null
  postcode?: string | null
  snoozed: boolean
}
export type Applicant = {
  id: string
  name: string
  receivesEmail: boolean
  visible?: boolean
}
export type Mortgage = {
  accountNumber?: string | null
  advisor: BareUser
  applicants: Applicant[]
  caseId?: string | null
  caseLink?: string | null
  crmLink?: string | null
  debtAfterPreferentialRate: number
  description: string
  dismissedNotInterested: boolean
  emailEveryone?: boolean
  expiryDate: string
  expired: boolean
  followOnRate: number
  givenCurrentBalance?: number | null
  givenCurrentBalanceAt?: string | null
  id: string
  isSharedOwnership: boolean
  lender: {
    id: string
    logo?: string | null
    logoBgColor?: string | null
    logoBgContrastColor?: string | null
    name: string
  }
  lifecycleModuleFlags?: Partial<Record<lifecycleModule, boolean>>
  loanAmount: number
  loanPurpose?: loanPurpose
  loanToValueAfterPreferentialRate: number
  monthlyFollowOnPayment: number
  monthlyPreferentialPayment: number
  monthsOfPreferentialRate: number
  monthsUntilEndOfTermAfterPreferentialRate: number
  monthsUntilPreferentialRateEnd: number
  notInterestedCategory?: notInterestedCategories | null
  paymentType?: paymentTypes
  pctOwned: number
  pointOfContactId: string
  pointOfContactIsExplicit: boolean
  preferentialRate: number
  productsAvailable: boolean
  prop: PropertyType
  propertyValue: number
  propertyValueAfterPreferentialRate: number
  scheme: paymentSchemes
  startDate: string
  state?: mortgageState | null
  switchState?:
    | "without-advice"
    | "internal-advice"
    | "external-advice"
    | "with-switch-support"
    | null
  term: number
  type: mortgageTypes
  viewAsConsumerId?: string
  selectedProductOptions: ProductOption[]
  switchStartDatePreference?: SwitchStartDatePreference | null
}
export type RawMortgage = Omit<
  Mortgage,
  "expired" | "prop" | "primary" | "viewAsConsumerId" | "selectedProductOptions"
> & {
  prop: Omit<PropertyType, "address"> & { address?: string | null }
  selectedProductOptions?: ProductOptionWithStrings[]
}

export interface Property {
  id: string
  address: string
  postcode?: string | null
  crmLink?: string | null
  mortgages: Mortgage[]
  value: number
  active?: boolean
  snoozed: boolean
}
