import { ErrorMessage as FormikError } from "formik"

import ErrorMessage from "../ErrorMessage"

interface Props {
  id?: string
  center?: boolean
  name: string
}

const FormError = ({ id, name, center }: Props) => {
  return (
    <FormikError
      name={name}
      render={(msg): React.ReactElement => (
        <ErrorMessage id={id} msg={msg} center={center} />
      )}
    />
  )
}

export default FormError
