import { Link } from "react-router-dom"

import { ReactComponent as ErrorIllustration } from "../../images/ErrorIllustration.svg"
import s from "./NotFoundError.module.scss"

function NotFoundError() {
  return (
    <div className={s.container}>
      <div className={s.notFoundError}>
        <ErrorIllustration className={s.errorImage} />
        <h1>Whoops there’s no one here</h1>
        <p>The lights are on but no one is home</p>
        <Link className={s.button} to="/">
          Go back
        </Link>
      </div>
    </div>
  )
}

export default NotFoundError
