import runtimeEnv from "@mars/heroku-js-runtime-env"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"

import { useAppSelector } from "../redux/store"

const env = runtimeEnv()

export default function useSentryUserScope() {
  const currentUserId = useAppSelector(
    (state) => state.auth.currentUser?.id || state.auth.currentUserId
  )
  const currentUserType = useAppSelector((state) => state.auth.currentUser?.type)
  const firmId = useAppSelector((state) => state.auth.firm?.id)
  const firmName = useAppSelector((state) => state.auth.firm?.name)
  const businessSide = useAppSelector((state) => state.auth.businessSide)

  useEffect(() => {
    if (currentUserId && env.REACT_APP_SENTRY_DSN) {
      let userForSentry: Sentry.User = {
        id: currentUserId,
        "User Type": currentUserType,
      }
      if (firmId && firmName) {
        userForSentry = {
          ...userForSentry,
          Firm: firmId,
          "Firm Name": firmName,
        }
        Sentry.getCurrentScope().setTag("firm.id", firmId)
        Sentry.getCurrentScope().setTag("firm", firmName)
      }
      Sentry.getCurrentScope().setUser(userForSentry)
      Sentry.getCurrentScope().setTag(
        "businessSide",
        businessSide?.toString() || "false"
      )
    }
  }, [businessSide, currentUserId, currentUserType, firmId, firmName])
}
