import { LabelPaths } from "../../../../hooks/blocks/util.types"

export const productSelectionSummaryPageBlocks = {
  lender: {
    selection: {
      summary: {
        title: {
          type: "text",
          description: "Heading element for the /product-selection/thanks page",
          default: "Selection summary",
        },
        removeProductButton: {
          labelContent: {
            type: "text",
            description: "Label for the remove product button",
            default: "Remove product",
          },
        },
        confirmation: {
          descriptionContent: {
            type: "markdown",
            description: "Confirmation description for the summary page",
            default: `Click ‘Confirm’ if you’re happy to move forward without advice.

This means you will not benefit from the same level of protection as a customer who has received advice on the suitability of the product switch (which may include the right to refer to the Financial Ombudsman Service).`,
          },
        },
        confirmationButton: {
          labelContent: {
            type: "text",
            description: "Label for the confirmation button",
            default: "Confirm",
          },
        },
        contactContent: {
          type: "text",
          description: "Contact content for the summary page",
          default: "How would you like to receive your Product Switch offer?",
        },
        switchStartContent: {
          type: "text",
          description: "Switch start content for the summary page",
          default: "When would you like your switch to happen?",
        },
      },
    },
  },
} as const

export type ProductSelectionSummaryLabelPaths = LabelPaths<
  typeof productSelectionSummaryPageBlocks
>
